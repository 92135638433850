import React, { useMemo } from 'react'
import { Typography, type TypographyProps } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import {
  TypographyFontWeight,
  ParagraphVariant,
  typography,
  typographyFontWeight,
  TypographyOmitsForTextComps,
} from '@dis/styles/src/theme'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { isFirstLetterRtl } from '@dis/utils'

type Props<C extends React.ElementType> = Omit<
  TypographyProps<C, { component?: C }>,
  TypographyOmitsForTextComps
> & {
  className?: string
  fontWeight?: TypographyFontWeight
  tKey?: string
  tValues?: Record<string, string | number | undefined>
  variant?: ParagraphVariant
}

/**
 * Custom Typography component for Paragraph with HTML formatters & predefined styles, see FIGMA for more: https://www.figma.com/file/fcurJwqBrHvPv9t47UCx96/Discovery-KPMG?type=design&node-id=37-9496&mode=design&t=GmoSkkVARydKNurU-0
 */
export const Paragraph = <C extends React.ElementType>({
  children,
  className,
  tKey,
  tValues,
  variant = 'body',
  fontWeight = 'regular',
  ...rest
}: Props<C>) => {
  const isRtlLanguage = useAppSelector(selectIsLanguageRtl)

  const { t } = useTranslation()

  const { fontSize, lineHeight } = typography.paragraph[variant]

  const isArabic = useMemo(() => {
    if (typeof children === 'string' && children.trim().length) {
      return isFirstLetterRtl(children)
    }

    return isRtlLanguage
  }, [children, isRtlLanguage])

  return (
    <Typography
      className={className}
      dir={isArabic ? 'rtl' : 'ltr'}
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight={typographyFontWeight[fontWeight]}
      {...rest}>
      {children ?? (tKey && <Trans t={t}>{t(tKey, tValues)}</Trans>)}
    </Typography>
  )
}
