import React, { MouseEventHandler } from 'react'
import { RoundIconButton, SvgImage } from '@dis/components'
import CommentOther from '@dis/assets/src/icons/CommentOther.svg'
import Comment from '@dis/assets/src/icons/Comment.svg'
import CommentOpen from '@dis/assets/src/icons/CommentOpen.svg'
import {
  buttonStyle,
  buttonTransition,
  buttonTransitionNext,
  buttonTransitionNextNoSolid,
} from '@dis/components/src/JourneyCell/styles'
import CommentOwner from '@dis/assets/src/icons/CommentOwner.svg'

interface Props {
  anchorEl?: HTMLElement
  className?: string
  handleOpen: MouseEventHandler<HTMLButtonElement>
  isOwner?: boolean
  isShiftButton?: boolean
  thread?: string
}

export const CommentRoundIcon = ({
  anchorEl,
  className,
  handleOpen,
  thread,
  isOwner,
  isShiftButton,
}: Props) => {
  return (
    <RoundIconButton
      icon={
        <SvgImage
          src={
            !anchorEl && thread && isOwner
              ? CommentOwner
              : !anchorEl && thread && !isOwner
                ? CommentOther
                : !anchorEl && !thread
                  ? Comment
                  : CommentOpen
          }
        />
      }
      outline="grey"
      className={`${buttonStyle({
        left: isShiftButton ? 10 : 2,
        top: 1,
        zIndex: 1,
      })}  ${!anchorEl && thread ? buttonTransitionNext : !anchorEl ? buttonTransition : buttonTransitionNextNoSolid} ${className}`}
      size="small"
      onClick={handleOpen}
    />
  )
}
