export type Ids = {
  journeyId?: number
  localeId?: string
  personaId?: number
  tenantId?: number
}

export const exportBacklogXlsxUrl = ({ tenantId, localeId }: Ids): string =>
  `/api/tenant/${tenantId}/exportuserstoriesxls/locale/${localeId}`

export const exportJourneyPdfUrl = ({ tenantId, journeyId, localeId }: Ids): string =>
  `/api/tenant/${tenantId}/exportjourneypdf/${journeyId}/locale/${localeId}`

export const exportJourneyXlsUrl = ({ tenantId, journeyId, localeId }: Ids): string =>
  `/api/tenant/${tenantId}/exportexcel/${journeyId}/locale/${localeId}`

export const exportJourneyXmlUrl = ({ tenantId, journeyId, localeId }: Ids): string =>
  `/api/tenant/${tenantId}/exportxml/${journeyId}/locale/${localeId}`

export const exportPersonaPdfUrl = ({ tenantId, personaId, localeId }: Ids): string =>
  `/api/tenant/${tenantId}/exportpersonapdf/${personaId}/locale/${localeId}`

export const exportTenantUsersXlsUrl = ({ localeId }: Ids): string =>
  `/api/exporttenantusersxls/locale/${localeId}`

export const uploadFileUrl = (tenantId: number) => `/api/tenant/${tenantId}/file`

export const getFileUrl = (tenantId?: number, fileId?: number) => {
  if (tenantId && fileId) {
    return `/api/tenant/${tenantId}/file/${fileId}`
  }

  return ''
}

export const importExcelToJourneyUrl = ({ tenantId, localeId }: Ids): string =>
  `/api/tenant/${tenantId}/importexcel/locale/${localeId}`

export const importCapabilityUrl = '/api/importcapability'

export const tenantTemplatesUrl = 'api/tenant/templates'

export const sectorsUrl = 'api/sectors'

export const negotiateUrl = (oid: string) => `/api/negotiate?userid=${oid}`

export const configJsonUrl = '/config.json'
