import React, { useCallback } from 'react'
import { default as MuiTableBody } from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useTable } from '@dis/context/src/table/useTable'
import { SortableList } from './dnd/SortableList'
import { DragHandleButton } from './dnd/DragHandleButton'
import { TableProps } from './types'

type Props<RowType> = Pick<TableProps<RowType>, 'tableBodyClassName' | 'bodyCols'>

export const TableBody = <RowType,>({ tableBodyClassName, bodyCols }: Props<RowType>) => {
  const { allowDnd, idExtractor, sortedRows } = useTable()

  const renderRow = useCallback(
    (row: RowType) => (
      <TableRow key={`row_${idExtractor?.(row)}`}>
        <>
          {allowDnd && (
            <TableCell>
              <DragHandleButton />
            </TableCell>
          )}

          {bodyCols?.({ rowData: row })}
        </>
      </TableRow>
    ),

    [allowDnd, bodyCols, idExtractor],
  )

  return (
    <MuiTableBody className={tableBodyClassName}>
      {allowDnd ? <SortableList<RowType> renderRow={renderRow} /> : sortedRows.map(renderRow)}
    </MuiTableBody>
  )
}
