import axios, { AxiosError, type AxiosInstance } from 'axios'
import { Store } from '@dis/redux'
import {
  setupCache,
  type AxiosStorage,
  buildMemoryStorage,
  buildKeyGenerator,
  defaultHeaderInterpreter,
} from 'axios-cache-interceptor'
import { hash } from 'object-code'
import { StorageValue } from 'axios-cache-interceptor/src/storage/types'
import { AxiosCacheInstance } from 'axios-cache-interceptor/src/cache/axios'
import {
  ExtendedAxiosError,
  ExtendedAxiosInstance,
  ExtendedAxiosRequestConfig,
  ExtendedAxiosResponse,
} from '@dis/types/src/api'
import { processUserSession } from '@dis/utils/src/msalInstance'
import { countHttpStatus, processError } from './utils'
import packageJson from '../../../package.json'

type AxiosGetImageInstance = Omit<AxiosCacheInstance, 'storage'> & {
  storage: AxiosStorage & {
    data?: Record<string, StorageValue | undefined>
  }
}

type InterceptorFlags = {
  isSecured?: boolean
  longTimeout?: boolean
}

const RESPONSE_TYPE = 'arraybuffer'
const ACCEPT_HEADER = 'application/json'

const initInterceptors = (axiosInstance: AxiosInstance, flags?: InterceptorFlags) => {
  axiosInstance.interceptors.request.use(
    // Success part
    async (request) => {
      if (flags?.isSecured) {
        const idToken = await processUserSession()

        const bearer = `Bearer ${idToken}`

        request.headers.Authorization = bearer
        request.headers['X-MS-TOKEN-AAD-ID-TOKEN'] = bearer
        request.headers['Cache-control'] = 'no-cache'
      }

      // Timeout 05:05 min according to timeout set on the backend
      request.timeout = (5 * 60 + 5) * 1_000 // ms

      return request
    },

    // Error part
    (error) => {
      return error
    },
  )

  axiosInstance.interceptors.response.use(
    // Success part
    (response) => {
      const httpStatus = countHttpStatus(response.status)

      const extendedAxiosResponse: ExtendedAxiosResponse = {
        ...response,
        ...httpStatus,
        data: httpStatus.OK ? response?.data : undefined,
        errorData: httpStatus.OK ? undefined : response?.data,
      }

      if (!httpStatus.OK) {
        processError(httpStatus, JSON.stringify(response))
      }

      return extendedAxiosResponse
    },

    // Error part
    (error: AxiosError) => {
      console.error(error)

      const enhancedError: ExtendedAxiosError<any> = {
        ...(error || {}),
        response: {
          config: (error?.response?.config || {}) as any,
          data: error?.response?.data,
          headers: error?.response?.headers || {},
          request: error?.response?.request,
          status: error?.response?.status || -1,
          statusText: error?.response?.statusText || '',
        },
      }

      const responseConfig = enhancedError.config as ExtendedAxiosRequestConfig<any>

      switch (enhancedError.code) {
        case AxiosError.ERR_BAD_REQUEST:
        case AxiosError.ERR_NETWORK:
        case AxiosError.ETIMEDOUT:
          enhancedError.response.status = -1
          break
      }

      const httpStatus = countHttpStatus(enhancedError.response.status)

      const extendedAxiosResponse: ExtendedAxiosResponse = {
        ...(enhancedError.response || {}),
        ...httpStatus,
        data: undefined,
        errorData: enhancedError.response.data,
      }

      if (!(enhancedError.code === AxiosError.ERR_NETWORK && responseConfig.avoidNetworkError)) {
        processError(httpStatus, JSON.stringify(enhancedError))
      }

      return extendedAxiosResponse
    },
  )
}

export class AxiosInstances {
  private static localAxiosInstanceHolder = axios.create({
    headers: {
      Accept: ACCEPT_HEADER,
    },
  })

  private static axiosInstanceHolder: ExtendedAxiosInstance

  private static axiosGetImageInstanceHolder: AxiosCacheInstance

  public static init = ({ getState }: Store) => {
    const { beUrl } = getState().general

    this.axiosInstanceHolder = axios.create({
      baseURL: beUrl,
      headers: {
        Accept: ACCEPT_HEADER,
        'X-App-Version': packageJson.version,
      },
      validateStatus: (httpCode) => httpCode !== 500,
    })

    this.axiosGetImageInstanceHolder = setupCache(
      axios.create({
        baseURL: beUrl,
        headers: {
          Accept: ACCEPT_HEADER,
        },
        responseType: RESPONSE_TYPE,
      }),
      {
        debug: console.info,
        generateKey: buildKeyGenerator(({ url }) => {
          return hash({
            url,
          })
        }),
        headerInterpreter: defaultHeaderInterpreter,
        storage: buildMemoryStorage(),
      },
    )

    initInterceptors(this.axiosInstanceHolder, {
      isSecured: true,
      longTimeout: true,
    })
    initInterceptors(this.axiosGetImageInstanceHolder, { isSecured: true })
  }

  public static get localAxiosInstance() {
    return this.localAxiosInstanceHolder
  }

  public static get axiosInstance() {
    return this.axiosInstanceHolder
  }

  public static get axiosGetImageInstance(): AxiosGetImageInstance {
    return this.axiosGetImageInstanceHolder
  }
}

initInterceptors(AxiosInstances.localAxiosInstance)
