import { ReactNode, useMemo } from 'react'
import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { generateTheme } from '@dis/styles'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'

type Props = {
  children: ReactNode
}

export const ThemeProvider = ({ children }: Props) => {
  const isRtlLanguage = useAppSelector(selectIsLanguageRtl)

  const localTheme = useMemo(
    () => generateTheme(isRtlLanguage),

    [isRtlLanguage],
  )

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={localTheme}>{children}</MuiThemeProvider>
    </StyledEngineProvider>
  )
}
