import React, { FC, useCallback, useMemo } from 'react'
import { FormControl, Select } from '@dis/form'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { SelectProps } from '@dis/components'
import { type UseFormSetValue, useWatch } from 'react-hook-form'
import { useAppSelector } from '@dis/redux'
import { selectPermissions } from '@dis/redux/src/security/selectors'
import { selectLanguage } from '@dis/redux/src/user/selectors'
import { CreateItem } from './CreateItem'
import { styles } from './styles'

type Props = {
  atlasOptions: SelectProps['options']
  control: FormControl<any>
  folderOptions?: SelectProps['options']
  forceTenantId?: number
  hasFolderSelect?: boolean
  refetchAtlasOptions: VoidFunction
  setValue: UseFormSetValue<any>
}

export const SelectAtlasAndFolder: FC<Props> = ({
  atlasOptions,
  control,
  folderOptions = [],
  forceTenantId,
  refetchAtlasOptions,
  hasFolderSelect = true,
  setValue,
}) => {
  const userPermissions = useAppSelector(selectPermissions)
  const language = useAppSelector(selectLanguage)

  const { t } = useTranslation()

  const atlasId = useWatch({ control, name: 'atlasId' })

  const { atlasPlaceholder, enhancedAtlasOptions } = useMemo(() => {
    const arr: SelectProps['options'] = []

    atlasOptions.forEach((atlasOption) => {
      const { value } = atlasOption

      if (!!value && (typeof value === 'string' || typeof value === 'number')) {
        arr.push(atlasOption)
      }
    })

    const placeholder = t(
      arr.length ? tKeys.common.atlasPlaceholder.default : tKeys.common.atlasPlaceholder.noAtlases,
    )

    return {
      atlasPlaceholder: placeholder,
      enhancedAtlasOptions: arr.sort((a, b) =>
        a.label.localeCompare(b.label, language, { sensitivity: 'variant' }),
      ),
    }
  }, [atlasOptions, language, t])

  const { folderPlaceholder, enhancedFolderOptions } = useMemo(() => {
    const arr: SelectProps['options'] = []

    folderOptions.forEach((folderOption) => {
      const { value } = folderOption

      if (!!value && (typeof value === 'string' || typeof value === 'number')) {
        arr.push(folderOption)
      }
    })

    let placeholder = t(tKeys.common.folderPlaceholder.default)

    if (!atlasId) {
      placeholder = t(tKeys.common.folderPlaceholder.noAtlas)
    } else if (!folderOptions.length) {
      placeholder = t(tKeys.common.folderPlaceholder.noFolders)
    }

    return {
      enhancedFolderOptions: arr.sort((a, b) =>
        a.label.localeCompare(b.label, language, { sensitivity: 'variant' }),
      ),
      folderPlaceholder: placeholder,
    }
  }, [atlasId, folderOptions, language, t])

  const handleChangeAtlas = useCallback(() => {
    setValue('folderId', undefined)
  }, [setValue])

  return (
    <>
      <Select
        name="atlasId"
        required
        fixedLabel
        displayEmpty
        label={t(tKeys.common.atlas)}
        formControl={control}
        hasPlaceholderInList={false}
        hasDropdownParentWidth
        onChange={handleChangeAtlas}
        options={enhancedAtlasOptions}
        dropdownClasses={styles.selectMenu}
        placeholder={atlasPlaceholder}
        variant="outlined">
        {userPermissions.canCreateNewAtlas && (
          <CreateItem forceTenantId={forceTenantId} refetchAtlasOptions={refetchAtlasOptions} />
        )}
      </Select>

      {hasFolderSelect && !!atlasId && (
        <Select
          name="folderId"
          fixedLabel
          label={t(tKeys.common.folder)}
          displayEmpty
          formControl={control}
          hasPlaceholderInList={false}
          hasDropdownParentWidth
          options={enhancedFolderOptions}
          dropdownClasses={styles.selectMenu}
          placeholder={folderPlaceholder}
          variant="outlined">
          <CreateItem
            atlasId={atlasId}
            forceTenantId={forceTenantId}
            refetchAtlasOptions={refetchAtlasOptions}
          />
        </Select>
      )}
    </>
  )
}
