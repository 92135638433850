/**
 * @generated SignedSource<<44d4462f7bc80a8cea471e837b46917a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type JourneyCommentStatus = "none" | "opened" | "resolved" | "%future added value";
export type JourneyResourceType = "cell" | "layer" | "%future added value";
export type JourneyThreadCommentListQueryInput = {
  journeyId: number;
  tenantId: number;
};
export type QueriesGetJourneyThreadCommentListQuery$variables = {
  input: JourneyThreadCommentListQueryInput;
};
export type QueriesGetJourneyThreadCommentListQuery$data = {
  readonly journeyThreadCommentList: {
    readonly items: ReadonlyArray<{
      readonly content: string;
      readonly created: string;
      readonly id: string;
      readonly metadata: {
        readonly userInfo: {
          readonly email: string;
          readonly name: string;
          readonly oid: string;
        };
      };
      readonly resourceId: string;
      readonly resourceType: JourneyResourceType;
      readonly status: JourneyCommentStatus;
      readonly threadCommentId: string | null | undefined;
      readonly updated: string | null | undefined;
    }>;
  };
};
export type QueriesGetJourneyThreadCommentListQuery = {
  response: QueriesGetJourneyThreadCommentListQuery$data;
  variables: QueriesGetJourneyThreadCommentListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "JourneyThreadCommentListQueryResult",
    "kind": "LinkedField",
    "name": "journeyThreadCommentList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "JourneyCommentDto",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resourceId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "resourceType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "threadCommentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "JourneyCommentMetadataDto",
            "kind": "LinkedField",
            "name": "metadata",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JourneyCommentUserMetadataDto",
                "kind": "LinkedField",
                "name": "userInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "oid",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesGetJourneyThreadCommentListQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesGetJourneyThreadCommentListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5f47166b265ed889735b61897a784a69",
    "id": null,
    "metadata": {},
    "name": "QueriesGetJourneyThreadCommentListQuery",
    "operationKind": "query",
    "text": "query QueriesGetJourneyThreadCommentListQuery(\n  $input: JourneyThreadCommentListQueryInput!\n) {\n  journeyThreadCommentList(input: $input) {\n    items {\n      content\n      created\n      id\n      resourceId\n      resourceType\n      status\n      threadCommentId\n      updated\n      metadata {\n        userInfo {\n          email\n          name\n          oid\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2abd0556e15648335e91646dc6e02856";

export default node;
