import { Box } from '@mui/material'
import { Modal, Paragraph } from '@dis/components'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { yup, useForm } from '@dis/form'
import { MoveRestoreForm } from '@dis/types/src/forms'
import { theme } from '@dis/styles'
import { useMoveRestoreModal } from './useMoveRestoreModal'
import { SelectAtlasAndFolder } from '../selectAtlasAndFolder/SelectAtlasAndFolder'
import { getSelectAtlasValidationSchemaPart } from '../selectAtlasAndFolder/utils'

export type MoveRestoreType = 'folder' | 'journey' | 'restore'

type Props = {
  autoNavigateToParent?: boolean
  folderId?: number
  journeyId?: number
  name?: string
  notificationNavigateToParent?: boolean
  onClose: () => void
  preventAutoNavigation?: boolean
  refetch?: () => void
  type: MoveRestoreType
}

const validationSchema: yup.ObjectSchema<MoveRestoreForm> = yup.object().shape({
  ...getSelectAtlasValidationSchemaPart(),
})

const msg = tKeys.modules.moveRestoreModal

export const MoveRestoreModal = ({
  folderId: moveFolderId,
  journeyId,
  name,
  onClose,
  refetch,
  type,
  preventAutoNavigation = false,
  autoNavigateToParent = true,
  notificationNavigateToParent = true,
}: Props) => {
  const { t } = useTranslation()

  const {
    control: formControl,
    handleSubmit,
    watch,
    setValue,
  } = useForm<MoveRestoreForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    validationSchema,
  })

  const atlasId = watch('atlasId')
  const folderId = watch('folderId')

  const {
    atlasName,
    atlasOptions,
    folderOptions,
    getFolderName,
    handleMoveFolder,
    handleMoveJourney,
    handleRestore,
    loading,
    refetchAtlasOptions,
  } = useMoveRestoreModal({
    atlasId,
    onClose,
    refetch,
    type,
  })

  const folderName = getFolderName(folderId)
  const target = folderName ? atlasName?.concat(' / ', folderName) : atlasName

  const onSubmitForm = handleSubmit((formData) => {
    if (journeyId) {
      if (type === 'restore') {
        handleRestore(formData, journeyId)
      } else if (type === 'journey') {
        handleMoveJourney(
          formData,
          journeyId,
          preventAutoNavigation,
          autoNavigateToParent,
          notificationNavigateToParent,
        )
      }
    } else if (moveFolderId && type === 'folder') {
      handleMoveFolder(formData, moveFolderId)
    }
  })

  return (
    <Modal
      isOpen
      onCrossBtnClick={onClose}
      title={t(msg[type].title)}
      isDisabled={loading}
      primaryBtnText={t(tKeys.common.submit)}
      onPrimaryBtnClick={onSubmitForm}
      height="auto"
      width="normal">
      <Box gap={theme.spacing(6)} display="flex" flexDirection="column">
        <Paragraph tKey={t(msg[type].text)} tValues={{ name }} variant="medium" />

        <SelectAtlasAndFolder
          atlasOptions={atlasOptions}
          control={formControl}
          folderOptions={folderOptions}
          hasFolderSelect={type !== 'folder' && !!atlasId}
          refetchAtlasOptions={refetchAtlasOptions}
          setValue={setValue}
        />

        {folderId || atlasId ? (
          <Paragraph
            py={5}
            variant="body"
            tKey={t(msg[type].info)}
            tValues={{
              target,
            }}
          />
        ) : null}
      </Box>
    </Modal>
  )
}
