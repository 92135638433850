import { ReactNode, useEffect, useState } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from '@dis/constants'
import { MsalProvider as MsalProviderComponent } from '@azure/msal-react'
import { useAppSelector } from '@dis/redux'
import { selectAzureAuthorityUrl, selectClientId } from '@dis/redux/src/general/selectors'
import { setMsalInstance as setMsalInstanceToGlobal } from '@dis/utils/src/msalInstance'

type Props = {
  children: ReactNode
}

export const MsalProvider = ({ children }: Props) => {
  const [msalInstance, setMsalInstance] = useState<PublicClientApplication>()

  const azureAuthorityUrl = useAppSelector(selectAzureAuthorityUrl)
  const clientId = useAppSelector(selectClientId)

  useEffect(() => {
    if (azureAuthorityUrl && clientId) {
      const instance = new PublicClientApplication({
        ...msalConfig,
        auth: {
          ...msalConfig.auth,
          authority: azureAuthorityUrl || undefined,
          clientId,
        },
      })

      instance.initialize().then(
        () => {
          setMsalInstanceToGlobal(instance)
          setMsalInstance(instance)
        },
        (error) => {
          console.error('Initializing msal instance failed:', error)
        },
      )
    }
  }, [azureAuthorityUrl, clientId])

  if (!msalInstance) {
    return <></>
  }

  return <MsalProviderComponent instance={msalInstance}>{children}</MsalProviderComponent>
}
