import { useCallback, useEffect, useRef, useState } from 'react'
import { dispatchedActions, store, useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { useRevert } from '@dis/hooks/src/useRevert'
import { useVersion, useVersionlist, useVersionlistBackForward } from '@dis/hooks/src/useVersioning'
import { WsEnhancedResponseMessage, WsMessageModel } from '@dis/types/src/wsModels'
import { useTenantPersona } from '@dis/hooks'
import { selectSelectedPersonaId } from '@dis/redux/src/personas/personasSelectors'
import { selectSelectedJourneyId } from '@dis/redux/src/journeys/journeysSelectors'
import { PersonaChangeNotification, PersonaDetailType } from '@dis/types/src/PersonaTypes'
import { JourneyData, JourneySideloads, JourneySubscribe } from '../journey/grid/types'

type UseRevisionControlVersionProps = {
  handleCloseForMenuItem: VoidFunction
  isJourney: boolean
  isPersona: boolean
  semaphore: boolean
}

export const useRevisionControlVersion = ({
  isPersona,
  isJourney,
  handleCloseForMenuItem,
  semaphore,
}: UseRevisionControlVersionProps) => {
  const [selectedVersionIndex, setSelectedVersionIndex] = useState<number>()

  const journeyDataBackup = useRef<JourneyData>()

  const onPersonaDataReceived = useCallback(
    (data?: WsEnhancedResponseMessage<PersonaDetailType | PersonaChangeNotification>) => {
      if (data) {
        if (data.backenable !== undefined) {
          dispatchedActions.personas.setIsBackEnable(data.backenable)
        }

        if (data.forwardenable !== undefined) {
          dispatchedActions.personas.setIsForwardEnable(data.forwardenable)
        }
      }
    },
    [],
  )

  useTenantPersona({
    callbacks: {
      back: onPersonaDataReceived,
      change: onPersonaDataReceived,
      forward: onPersonaDataReceived,
      revert: onPersonaDataReceived,
      subscribe: onPersonaDataReceived,
    },
    semaphore: isPersona,
  })

  const selectedPersonaId = useAppSelector(selectSelectedPersonaId)
  const selectedTenantId = useAppSelector(selectSelectedTenantId)
  const selectedJourneyId = useAppSelector(selectSelectedJourneyId)

  const { data: versionHistory, loading: isVersionListLoading } = useVersionlist({
    isJourney,
    isPersona,
    semaphore,
  })

  const { send: sendRevert } = useRevert()

  const onVersionDataReceived = useCallback(
    (newData: WsEnhancedResponseMessage<PersonaDetailType | JourneySubscribe>) => {
      if (isPersona) {
        dispatchedActions.personas.setPersonaSelectedVersionData(newData.data as PersonaDetailType)
      } else {
        const newState: JourneyData = {
          sideloads: newData?.sideloads || [],
          subscribe: (newData as WsEnhancedResponseMessage<JourneySubscribe>)?.data || {},
          version: newData?.version || 0,
        }

        journeyDataBackup.current = store.getState().journeys.journeyData

        dispatchedActions.journeys.setJourneysStructure(undefined)
        dispatchedActions.journeys.setJourneyData(newState)
        dispatchedActions.journeys.setIsHistoryVersionPreview(true)
      }
    },
    [isPersona],
  )

  const { send: sendVersion } = useVersion({
    isPersona,
    onData: onVersionDataReceived,
  })

  const {
    send: sendBackForward,
    backForwardEnable,
    data: backForwardData,
  } = useVersionlistBackForward({
    isPersona,
    onData: onVersionDataReceived,
  })

  const onClickSetVersion = useCallback(
    (versionIndex: number) => () => {
      handleCloseForMenuItem()
      setSelectedVersionIndex(versionIndex)
      dispatchedActions.journeys.setCanEdit(false)

      sendVersion({
        tenantId: selectedTenantId || 0,
        version: {
          data: versionIndex,
          id: isPersona ? selectedPersonaId?.toString() : selectedJourneyId?.toString(),
          model: isPersona ? WsMessageModel.Persona : WsMessageModel.Document,
        },
      })
    },
    [
      handleCloseForMenuItem,
      isPersona,
      selectedJourneyId,
      selectedPersonaId,
      selectedTenantId,
      sendVersion,
    ],
  )

  const onClickSetVersionBack = useCallback(
    (versionIndex?: number) => () => {
      const version = backForwardData ? backForwardData?.version : versionIndex
      if (version) {
        setSelectedVersionIndex(version - 1)
        dispatchedActions.journeys.setCanEdit(false)

        sendBackForward({
          tenantId: selectedTenantId || 0,
          version: {
            data: version - 1,
            id: isPersona ? selectedPersonaId?.toString() : selectedJourneyId?.toString(),
            model: isPersona ? WsMessageModel.Persona : WsMessageModel.Document,
          },
        })
      }
    },
    [
      backForwardData,
      isPersona,
      selectedJourneyId,
      selectedPersonaId,
      selectedTenantId,
      sendBackForward,
    ],
  )

  const onClickSetVersionForward = useCallback(() => {
    const version = backForwardData ? backForwardData?.version : undefined
    if (version) {
      setSelectedVersionIndex(version + 1)
      dispatchedActions.journeys.setCanEdit(false)

      sendBackForward({
        tenantId: selectedTenantId || 0,
        version: {
          data: version + 1,
          id: isPersona ? selectedPersonaId?.toString() : selectedJourneyId?.toString(),
          model: isPersona ? WsMessageModel.Persona : WsMessageModel.Document,
        },
      })
    }
  }, [
    backForwardData,
    isPersona,
    selectedJourneyId,
    selectedPersonaId,
    selectedTenantId,
    sendBackForward,
  ])

  const acceptSelectedVersion = useCallback(() => {
    dispatchedActions.personas.setPersonaSelectedVersionData(undefined)
    dispatchedActions.journeys.setCanEdit(true)

    setSelectedVersionIndex(undefined)

    sendRevert(
      {
        revert: {
          data: selectedVersionIndex,
          id: isPersona ? selectedPersonaId?.toString() : selectedJourneyId?.toString(),
          model: isPersona ? WsMessageModel.Persona : WsMessageModel.Document,
        },
        tenantId: selectedTenantId || 0,
      },
      () => {
        if (isJourney) {
          dispatchedActions.journeys.setForceRefetchFlag(true)
        }
      },
    )
    if (isJourney) {
      dispatchedActions.journeys.setJourneysStructure(undefined)
      journeyDataBackup.current = undefined
      dispatchedActions.journeys.setIsHistoryVersionPreview(false)
    }
  }, [
    isJourney,
    isPersona,
    selectedJourneyId,
    selectedPersonaId,
    selectedTenantId,
    selectedVersionIndex,
    sendRevert,
  ])

  const declineSelectedVersion = useCallback(() => {
    setSelectedVersionIndex(undefined)
    dispatchedActions.journeys.setCanEdit(true)

    dispatchedActions.personas.setPersonaSelectedVersionData(undefined)
    if (isJourney && journeyDataBackup.current) {
      dispatchedActions.journeys.setJourneysStructure(undefined)
      dispatchedActions.journeys.setJourneyData(journeyDataBackup.current)
      journeyDataBackup.current = undefined
      dispatchedActions.journeys.setIsHistoryVersionPreview(false)
      dispatchedActions.journeys.setForceRefetchFlag(true)
    }
  }, [isJourney])

  useEffect(() => {
    if (isPersona) {
      if (backForwardData?.data && backForwardData.version && backForwardData?.version) {
        dispatchedActions.personas.setPersonaVersion(backForwardData?.version)
      }
      dispatchedActions.personas.setIsBackEnable(backForwardEnable.backEnable)
      dispatchedActions.personas.setIsForwardEnable(backForwardEnable.forwardEnable)
    } else {
      dispatchedActions.journeys.setIsBackEnable(backForwardEnable.backEnable)
      dispatchedActions.journeys.setIsForwardEnable(backForwardEnable.forwardEnable)
    }
  }, [backForwardData, backForwardData?.data, backForwardEnable, isPersona])

  useEffect(() => {
    if (isJourney) {
      if (
        backForwardData?.data &&
        backForwardData.version &&
        backForwardData?.userId &&
        backForwardData?.version
      ) {
        const journeySubscribe = backForwardData?.data as JourneySubscribe

        const journeySideloads = backForwardData?.sideloads as JourneySideloads[] | undefined

        // Signature of received data and storing it
        dispatchedActions.journeys.setJourneySignature({
          userId: backForwardData?.userId,
          version: backForwardData?.version,
        })

        // Set undefined to force journey re-render
        dispatchedActions.journeys.setJourneysStructure(undefined)

        dispatchedActions.journeys.setJourneyData({
          sideloads: journeySideloads,
          subscribe: journeySubscribe,
          version: backForwardData.version,
        })
      }
    }
  }, [backForwardData, isJourney])

  return {
    acceptSelectedVersion,
    declineSelectedVersion,
    isVersionListLoading,
    onClickSetVersion,
    onClickSetVersionBack,
    onClickSetVersionForward,
    selectedVersionIndex,
    versions: versionHistory?.versions || [],
  }
}
