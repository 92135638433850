import React, { useCallback } from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TenantCard } from '@dis/modules/src/tenant/TenantCard'
import { TenantList, Tenant } from '@dis/types/src/TenantTypes'
import EditIcon from '@dis/assets/src/icons/EditIcon.svg'
import { colors } from '@dis/styles'
import { tKeys } from '@dis/languages'
import TrashIcon from '@dis/assets/src/icons/TrashIcon.svg'
import { useAppSelector } from '@dis/redux'
import { selectUserRole } from '@dis/redux/src/security/selectors'
import { UserRole } from '@dis/types/src/UsersAndRoles'

type Props = {
  openDeleteTenantModal: (data: Tenant) => () => void
  showEditModal: (data: Tenant) => () => void
  tenants?: TenantList['tenants']
}

export const CardView = ({ tenants, showEditModal, openDeleteTenantModal }: Props) => {
  const { t } = useTranslation()
  const actualUserRole = useAppSelector(selectUserRole)

  const menu = useCallback(
    (tenant: Tenant) => {
      const hideIcon = {
        icon: EditIcon,
        iconColor: colors.gray.gray80,
        label: t(tKeys.common.edit),
        onClick: showEditModal(tenant),
      }

      const trashIcon = {
        icon: TrashIcon,
        iconColor: colors.gray.gray80,
        isRed: true,
        label: t(tKeys.common.delete),
        onClick: openDeleteTenantModal(tenant),
      }

      return [hideIcon, trashIcon]
    },
    [openDeleteTenantModal, showEditModal, t],
  )

  return (
    <>
      <Grid container spacing={{ md: 6, sm: 3 }} columns={{ md: 12, sm: 8, xs: 4 }}>
        {tenants?.map((tenant) => (
          <Grid item xs={4} sm={4} md={4} key={tenant.id}>
            <TenantCard
              tenantData={tenant}
              menu={actualUserRole === UserRole.KpmgAdmin ? menu(tenant) : undefined}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
