import { type EnhancedStore, nanoid } from '@reduxjs/toolkit'
import { typedObjectEntries } from '@dis/utils'

export const generateId = () => {
  return process.env.NODE_ENV === 'test' ? 'test-id' : nanoid()
}

type ReduxActions = Record<string, (...params: any) => any>

type Actions = Record<string, ReduxActions>

const mapDispatch = (actions: ReduxActions, store: EnhancedStore<any, any, any>) =>
  Object.assign(
    {},
    ...Object.entries(actions).map(([key, action]) => ({
      [key]: (...params: any) => {
        return store.dispatch(action(...params))
      },
    })),
  )

export const mapDispatchToActions = <T extends Actions>(actions: T, store: EnhancedStore) => {
  const result = Object.assign(
    {},
    ...typedObjectEntries(actions).map(([reducer, reducerActions]) => ({
      [reducer]: mapDispatch(reducerActions, store),
    })),
  )

  return result as T
}
