import React, { FC, useCallback, useMemo } from 'react'
import { FormGroup, TextareaAutosize, type TextareaAutosizeProps } from '@mui/material'
import { type FieldError } from 'react-hook-form'
import { cx } from '@emotion/css'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { isFirstLetterRtl } from '@dis/utils'
import { ErrorNotice } from '../ErrorNotice/ErrorNotice'
import { styles } from './styles'
import { unsanitize } from '../utils'

export type TextAreaProps = Omit<TextareaAutosizeProps, 'value' | 'autocomplete'> & {
  className?: string
  error?: FieldError
  value?: string | number
}

export const TextArea: FC<TextAreaProps> = ({
  className,
  error,
  onChange: onChangeProp,
  value,
  ...restProps
}) => {
  const isRtlLanguage = useAppSelector(selectIsLanguageRtl)

  let enhancedValue = value

  if (enhancedValue === undefined) {
    enhancedValue = ''
  }

  let unsanitizedValue = enhancedValue

  if (typeof enhancedValue === 'string' && enhancedValue) {
    unsanitizedValue = unsanitize(enhancedValue)
  }

  const isArabic = useMemo(
    () =>
      unsanitizedValue?.toString().length
        ? isFirstLetterRtl(unsanitizedValue.toString())
        : isRtlLanguage,
    [isRtlLanguage, unsanitizedValue],
  )

  const onChangeWrapper = useCallback<NonNullable<TextareaAutosizeProps['onChange']>>(
    (e) => {
      onChangeProp?.(e)
    },
    [onChangeProp],
  )

  return (
    <FormGroup>
      <>
        <TextareaAutosize
          {...restProps}
          autoComplete="false"
          className={cx(styles.textarea(isArabic ? 'rtl' : 'ltr'), className)}
          onChange={onChangeWrapper}
          value={unsanitizedValue}
        />
        <ErrorNotice error={error} />
      </>
    </FormGroup>
  )
}
