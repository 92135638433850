import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { CheckboxForm } from '@dis/modules/src/backlog/types'
import { checkboxesDefaultValues } from '@dis/modules/src/backlog/constants'
import cloneDeep from 'lodash/cloneDeep'

type BacklogReducerState = {
  filter: CheckboxForm
}

const initialState: BacklogReducerState = {
  filter: checkboxesDefaultValues,
}

export const backlogSlice = createSlice({
  initialState,
  name: 'backlog',
  reducers: {
    init: () => initialState,
    setBacklogFilter: (state, action: PayloadAction<CheckboxForm>) => {
      state.filter = cloneDeep(action.payload)
    },
  },
})
