import { ReactNode, useMemo } from 'react'
import { Link as LinkComponent, type LinkProps } from 'react-router'
import { getRouteUrl, RouteNames, generateRouteObjects } from '@dis/routing'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { useAtlas, useFolder, useTenantPersona } from '@dis/hooks'
import { Paragraph } from '@dis/components'
import { useAppSelector } from '@dis/redux'
import { selectSelectedJourneyName } from '@dis/redux/src/journeys/journeysSelectors'
import { selectSelectedTenantName } from '@dis/redux/src/tenants/tenantsSelectors'
import { cx } from '@emotion/css'
import { styles } from './styles'

const routeObjects = generateRouteObjects()

// A list of routes to hide its name in the breadcrumb
const textBlacklist: Array<keyof typeof tKeys.routes> = ['tenants']

type Props = Omit<LinkProps, 'to'> & {
  icon?: ReactNode
  isLast: boolean
  routeKey: RouteNames
  routeToKey?: RouteNames
}

export const Link = ({ isLast, routeKey, routeToKey, icon }: Props) => {
  const { t } = useTranslation()

  const journeyTitle = useAppSelector(selectSelectedJourneyName)
  const tenantName = useAppSelector(selectSelectedTenantName)

  const { subscribeData: atlasName } = useAtlas()
  const { subscribeData: folderName } = useFolder({ autoRefetch: { change: true } })
  const { persona } = useTenantPersona({
    semaphore: routeKey === 'persona',
  })

  const { title } = useMemo(() => {
    const titles: Partial<Record<keyof typeof tKeys.routes, string | undefined>> = {
      atlas: atlasName?.name,
      folder: folderName?.name,
      journey: journeyTitle,
      journeyFolder: journeyTitle,
      persona: persona?.info?.name,
      recycleBinJourney: journeyTitle,
      tenant: tenantName,
    }

    const title = titles[routeKey] || t(routeObjects[routeKey].title)

    return { title: textBlacklist.includes(routeKey) ? null : title }
  }, [
    atlasName?.name,
    folderName?.name,
    journeyTitle,
    persona?.info?.name,
    routeKey,
    t,
    tenantName,
  ])

  if (!routeToKey) return null

  const to = getRouteUrl(routeToKey)

  return (
    <LinkComponent to={to} className={cx(styles.link, { [styles.lastLink]: isLast })}>
      <div className={styles.stack} data-testid="breadcrumb-link">
        {icon}
        {!!title && (
          <Paragraph variant="small" className={styles.text}>
            {title}
          </Paragraph>
        )}
      </div>
    </LinkComponent>
  )
}
