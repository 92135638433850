import { useRef } from 'react'
import { useNavigate } from 'react-router'
import { RouteVariables, RouteNames, getRouteUrl } from '@dis/routing'

type UseNavToRet = {
  navigate: (routeKey: RouteNames, variables?: RouteVariables) => void
  navigateFactory: (routeKey: RouteNames, variables?: RouteVariables) => VoidFunction
}

export const useNavTo = (): UseNavToRet => {
  const originalNavigate = useNavigate()

  const ret = useRef({
    navigate: (routeKey: RouteNames, variables?: RouteVariables) =>
      originalNavigate(getRouteUrl(routeKey, variables)),
    navigateFactory: (routeKey: RouteNames, variables?: RouteVariables) => () => {
      originalNavigate(getRouteUrl(routeKey, variables))
    },
  })

  return ret.current
}
