import { MouseEventHandler, ReactNode, useCallback } from 'react'
import { useImageData } from '@dis/hooks'
import { cx } from '@emotion/css'
import { DISALLOW_DND } from '@dis/constants'

type Props = {
  children?: ReactNode
  className?: string
  dataCallback?: (imgData: string) => void
  hasStoppedClickPropagation?: boolean
  priority?: number
  src?: string
  url?: string
}

export const PromisedImg = ({
  dataCallback,
  url,
  className,
  hasStoppedClickPropagation = true,
  children,
  src,
  priority = 0,
}: Props) => {
  const data = useImageData(url || '', priority)
  dataCallback && dataCallback(data)

  const selectedSrc = src || data

  const handleClickImage = useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.stopPropagation()
  }, [])

  if (selectedSrc) {
    return (
      <img
        src={selectedSrc}
        className={cx(className, DISALLOW_DND)}
        onClick={hasStoppedClickPropagation ? handleClickImage : undefined}
      />
    )
  } else if (children) {
    return <>{children}</>
  }

  return <></>
}
