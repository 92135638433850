import { useCallback } from 'react'
import { Button, List, ListItem } from '@mui/material'
import { SvgImage } from '@dis/components'
import BackIcon from '@dis/assets/src/icons/BackIcon.svg'
import { useTranslation } from 'react-i18next'
import { tKeys } from '@dis/languages'
import { useNavTo } from '@dis/hooks'
import { TutorialSidebarContent } from '../TutorialSidebarContent'
import { styles } from '../styles'
import { TutorialNavigationBar } from '../TutorialNavigationBar'
import { TextAndButtonHeader } from '../TextAndButtonHeader'
import { ids, Settings } from '../tutorialConstants'
import { LanguageSelection } from './LanguageSelection'
import { CapabilityManagement } from './CapabilityManagement'
import { BacklogManagement } from './BacklogManagement'
import { useScroll } from '../useScroll'

const msg = tKeys.tutorials.settings

export const TutorialSettings = () => {
  const { t } = useTranslation()
  const { navigate } = useNavTo()
  const { scrollToComponent } = useScroll()

  const navigateBack = useCallback(() => {
    navigate('tutorial')
  }, [navigate])

  return (
    <>
      <div className={styles.containerScreenStyle}>
        <div className={styles.sidebarStyle}>
          <Button
            variant="text"
            color="primary"
            onClick={navigateBack}
            className={styles.navigateBackBtn}>
            <SvgImage src={BackIcon} />
            {t(tKeys.tutorials.tutorialScreenTitle)}
          </Button>
          <TutorialSidebarContent
            initial={ids.settings}
            onSelectedItemChange={() => ids.settings}
          />
        </div>
        <div className={styles.contentStyle}>
          <div className={styles.contentInnerStyle}>
            <TextAndButtonHeader header={t(msg.header)} />
            <List
              sx={{
                listStylePosition: 'inside',
                listStyleType: 'disc',
              }}>
              <ListItem
                sx={{ cursor: 'pointer' }}
                className={styles.menuText}
                onClick={scrollToComponent(Settings.LanguageSelection)}>
                1.1 Language selection
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Settings.CapabilityManagement)}
                className={styles.menuText}>
                1.2 Capability management
              </ListItem>
              <ListItem
                sx={{ cursor: 'pointer' }}
                onClick={scrollToComponent(Settings.BacklogManagement)}
                className={styles.menuText}>
                1.3 Backlog management
              </ListItem>
            </List>
            <LanguageSelection id={Settings.LanguageSelection} />
            <CapabilityManagement id={Settings.CapabilityManagement} />
            <BacklogManagement id={Settings.BacklogManagement} />
            <TutorialNavigationBar />
          </div>
        </div>
      </div>
    </>
  )
}
