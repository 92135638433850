import { useEffect } from 'react'
import { dispatchedActions } from '@dis/redux'
import { errorCodeModal, ErrorCodeModalProps } from '@dis/modals/src/errorCodeModal'

export type UseErrorCodeModalProps = ErrorCodeModalProps & {
  disabled?: boolean
}
// Don't use rest/spread parameter (e.g. ...restProps) because then the useEffect hook listens to the instance of this
// restProps object instead of listening to the changes of the objects inside the restProps.
export const useErrorCodeModal = ({
  disabled = false,
  errorCode,
  defaultContent,
  primaryBtnText,
  onCrossBtnClick,
  priority,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  secondaryBtnText,
  title,
  hasCrossClose,
}: UseErrorCodeModalProps) => {
  useEffect(() => {
    if (!disabled && errorCode) {
      const modalId = dispatchedActions.centralModalDialog.showModalDialog(
        errorCodeModal({
          defaultContent,
          errorCode,
          hasCrossClose,
          onCrossBtnClick,
          onPrimaryBtnClick,
          onSecondaryBtnClick,
          primaryBtnText,
          priority,
          secondaryBtnText,
          title,
        }),
      )

      return () => {
        dispatchedActions.centralModalDialog.hideModalDialog(modalId)
      }
    }
  }, [
    defaultContent,
    disabled,
    errorCode,
    hasCrossClose,
    onCrossBtnClick,
    onPrimaryBtnClick,
    onSecondaryBtnClick,
    primaryBtnText,
    priority,
    secondaryBtnText,
    title,
  ])

  return undefined
}
