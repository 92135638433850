import CsFlag from '@dis/assets/src/icons/CsFlag.svg'
import FeFlag from '@dis/assets/src/icons/FeFlag.svg'
import EnFlag from '@dis/assets/src/icons/EnFlag.svg'
import ArFlag from '@dis/assets/src/icons/ArFlag.svg'
import DeFlag from '@dis/assets/src/icons/DeFlag.svg'
import { LanguagesUnion } from '@dis/constants'

export const getFlagIcon = (langCode: LanguagesUnion) => {
  // TODO: fix flags when figma is ready
  switch (langCode) {
    case 'en':
      return EnFlag
    case 'cs':
      return CsFlag
    case 'ar':
      return ArFlag
    case 'de':
      return DeFlag
    case 'fr':
      return FeFlag
    default:
      return ''
  }
}
