import { type Unsubscribe } from '@reduxjs/toolkit'
import { Api, AxiosInstances, WebsocketApi } from '@dis/api'
import { logoutPopup, processUserSession } from '@dis/utils/src/msalInstance'
import { GetWsNegotiation } from '@dis/types/src/AxiosTypes'
import { ExtendedAxiosResponse } from '@dis/types/src/api'
import { ExtendedError } from '@dis/utils'
import { Language } from '@dis/types/src/GeneralTypes'
import { LANGUAGES } from '@dis/constants'
import i18next from 'i18next'
import { negotiateUrl } from '@dis/api/src/endpoints'
import { backendErrorCodeMap } from '@dis/utils/src/beErrorCodeMap'
import { securitySlice } from './securitySlice'
import { actions } from '../'
import type { AppStartListening, AppListenerEffectAPI } from '../'
import { selectIsArabicLanguageAllowed, selectUserOid } from './selectors'

const onSendWsMessageCallback = async (isLogout: boolean) => {
  if (!isLogout) {
    await processUserSession()
  }
}

// Websocket EP URL contains access token so for refreshing websocket connection it's necessary to retrieve new URL
const wsReconnectCallback = (userOid: string, apiProps: AppListenerEffectAPI) => async () => {
  await negotiate(userOid, apiProps)
}

export const loginSuccess = async (_: any, apiProps: AppListenerEffectAPI) => {
  const userOid = selectUserOid(apiProps.getState())

  if (userOid) {
    apiProps.dispatch(securitySlice.actions.setIsAuthenticated(true))

    Api.setOnSendCallback(onSendWsMessageCallback)
    WebsocketApi.setReconnectCallback(wsReconnectCallback(userOid, apiProps))

    await negotiate(userOid, apiProps)
  } else {
    throw new Error('handleLoginSuccess saga: User ID is missing!')
  }
}

export const logout = ({ dispatch }: AppListenerEffectAPI) => {
  // Lock screen because it's necessary to reload app to clean data
  const modalLoader = actions.centralModalLoader.showModalLoader()
  dispatch(modalLoader)

  // Close socket to stop receiving notifications
  WebsocketApi.close()

  // Unsubscribe all and clear data
  Api.clearAll()

  dispatch(securitySlice.actions.setUserOid())
  dispatch(securitySlice.actions.setAccessTokenExpireTimestamp())
  dispatch(securitySlice.actions.setIsAuthenticated(false))

  // Show logout popup with a delay to let API successfully send unsubscribe calls
  setTimeout(logoutPopup, 500)
}

export const logoutThisDevice = (_: any, listenerApi: AppListenerEffectAPI) => {
  logout(listenerApi)
}

export const logoutAllDevices = (_: any, listenerApi: AppListenerEffectAPI) => {
  // Trigger BE to notify all my devices about logout
  Api.sendLogout({
    logout: {
      id: '',
    },
    tenantId: 0,
  })

  logout(listenerApi)
}

// Get user info and URL for websocket
export const negotiate = async (oid: string, { dispatch, getState }: AppListenerEffectAPI) => {
  const modalLoader = actions.centralModalLoader.showModalLoader()

  // Show modal loader asynchronously in a new thread otherwise the loader is not shown
  setTimeout(() => {
    dispatch(modalLoader)
  }, 0)

  let inLoop = true

  while (inLoop) {
    const response = (await AxiosInstances.axiosInstance.get(negotiateUrl(oid), {
      avoidNetworkError: true,
    })) as ExtendedAxiosResponse<GetWsNegotiation>

    if (response.NETWORK_ERROR) {
      await new Promise((resolve) => {
        setTimeout(resolve, 3_000)
      })
      continue
    } else if (response.CLIENT_ERROR || response.SERVER_ERROR) {
      inLoop = false

      if (
        response.status === 400 &&
        response.errorData?.code === backendErrorCodeMap.invalidAppVersion.code
      ) {
        throw new ExtendedError({
          message:
            response.errorData?.message ||
            'This application is not compatible with the backend API.',
        })
      }

      throw new ExtendedError({
        errorCode: response.errorData?.code,
        location: 'negotiate saga',
        message:
          response?.errorData?.message ||
          'An error has occurred during downloading user negotiate!',
        stackTrace: response.errorData,
      })
    } else if (response.OK) {
      if (response.data?.webPubSubConnection?.url) {
        WebsocketApi.init(response.data.webPubSubConnection.url)
      } else {
        console.error('negotiate saga: Missing URL for websocket!')
        dispatch(actions.security.logoutThisDevice())
        inLoop = false
        return
      }

      dispatch(
        actions.security.setUserRole({
          userRole: response.data.userInfo.role || undefined,
          userTenantId: response.data.userInfo.tenantId || undefined,
        }),
      )

      dispatch(actions.user.setUserEmail(response.data.userInfo.email))

      dispatch(actions.user.setUserName(response.data.userInfo.name))

      if (response.data.userInfo.locale) {
        const isArabicLanguageAllowed = selectIsArabicLanguageAllowed(getState())

        let locale = response.data.userInfo.locale
        if (locale.includes('_')) {
          locale = locale.split('_')[0]
        }

        if (locale === LANGUAGES.ar && !isArabicLanguageAllowed) {
          locale = LANGUAGES.en
        }

        dispatch(actions.user.setUserLanguage(locale as Language))
        i18next.changeLanguage(locale)

        dispatch(actions.user.setUserName(response.data.userInfo.name))

        document.documentElement.setAttribute('lang', locale)

        if (locale === LANGUAGES.ar) {
          document.documentElement.setAttribute('dir', 'rtl')
        } else {
          document.documentElement.setAttribute('dir', '')
        }
      }
    }

    inLoop = false
    dispatch(actions.centralModalLoader.hideModalLoader(modalLoader))
    return
  }
}

export const setupSecurityListeners = (startListening: AppStartListening): Unsubscribe => {
  const listeners = [
    startListening({
      actionCreator: securitySlice.actions.logoutThisDevice,
      effect: logoutThisDevice,
    }),
    startListening({
      actionCreator: securitySlice.actions.logoutAllDevices,
      effect: logoutAllDevices,
    }),
    startListening({
      actionCreator: securitySlice.actions.loginSuccess,
      effect: loginSuccess,
    }),
  ]

  return () => listeners.forEach((unsubscribe) => unsubscribe())
}
