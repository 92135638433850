import { useCallback } from 'react'
import { IconButton, Modal as MuiModal, type ModalProps } from '@mui/material'
import PlainDeleteIcon from '@dis/assets/src/icons/PlainDeleteIcon.svg'
import { cx, theme } from '@dis/styles'
import { NotificationModalProps } from '@dis/types/src/NotificationModalDialogTypes'
import { DISALLOW_DND } from '@dis/constants'
import { NotificationModalMainContent } from './NotificationModalMainContent'
import { NotificationModalFooter } from './NotificationModalFooter'
import { styles } from './NotificationModalStyles'
import { SvgImage } from '../SvgImage/SvgImage'
import { Paragraph } from '../Typography/Paragraph'

export const NotificationModal = ({
  children,
  disableEscapeKeyDown = false,
  hasCrossBtn = true,
  isOpen = false,
  onCrossBtnClick,
  onModalDestroyed,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  primaryBtnText,
  secondaryBtnText,
  severity = 'normal',
  title,
}: NotificationModalProps) => {
  const onClose = useCallback<NonNullable<ModalProps['onClose']>>(
    (_, reason) => {
      if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
        if (!disableEscapeKeyDown) {
          onCrossBtnClick?.()
        }
      } else {
        onModalDestroyed?.()
      }
    },
    [disableEscapeKeyDown, onCrossBtnClick, onModalDestroyed],
  )

  return (
    <MuiModal
      open={isOpen}
      disableScrollLock
      className={styles.root}
      componentsProps={{ backdrop: { className: DISALLOW_DND } }}
      onClose={onClose}
      disableEscapeKeyDown={disableEscapeKeyDown}>
      <div className={cx(styles.modalContainer, DISALLOW_DND)}>
        <div className={styles.closeIconContainer}>
          {hasCrossBtn && onCrossBtnClick && (
            <>
              <IconButton onClick={onCrossBtnClick} className={styles.closeIconButton}>
                <SvgImage
                  src={PlainDeleteIcon}
                  className={styles.closeIcon}
                  width={theme.spacing(6)}
                  height={theme.spacing(6)}
                />
              </IconButton>
            </>
          )}
        </div>

        <div className={styles.innerContainer}>
          <div className={styles.textContainer}>
            <NotificationModalMainContent severity={severity} title={title} />

            {typeof children === 'string' ? (
              <Paragraph variant="medium">{children}</Paragraph>
            ) : (
              children
            )}
          </div>
          <NotificationModalFooter
            severity={severity}
            onPrimaryBtnClick={onPrimaryBtnClick}
            onSecondaryBtnClick={onSecondaryBtnClick}
            primaryBtnText={primaryBtnText}
            secondaryBtnText={secondaryBtnText}
          />
        </div>
      </div>
    </MuiModal>
  )
}
