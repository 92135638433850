import { yup } from '@dis/form'
import { AtlasFolderInlineChangeForm } from '@dis/types/src/forms'
import { WsMessageModel } from '@dis/types/src/wsModels'

export const validationSchema: yup.ObjectSchema<AtlasFolderInlineChangeForm> = yup.object().shape({
  id: yup.number().required(),
  model: yup
    .mixed<WsMessageModel.Atlas | WsMessageModel.Folder>()
    .oneOf([WsMessageModel.Atlas, WsMessageModel.Folder])
    .required(),
  name: yup.string().required(),
})
