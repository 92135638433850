import { useCallback } from 'react'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { useRouteMatch } from './useRouteMatch'

export const useShareJourney = () => {
  const tenantId = useAppSelector(selectSelectedTenantId)
  const { t } = useTranslation()
  const loc = document.location

  const { isAtlas, isFolder } = useRouteMatch()

  const handleShareJourney = useCallback(
    (atlasId?: number, folderId?: number, journeyId?: number) => () => {
      let typeLink = folderId
        ? `/tenants/${tenantId}/atlases/${atlasId}/folders/${folderId}/journeys/${journeyId}`
        : `/tenants/${tenantId}/atlases/${atlasId}/journeys/${journeyId}`

      if (isAtlas) typeLink = `/tenants/${tenantId}/atlases/${atlasId}/journeys/${journeyId}`

      if (isFolder)
        typeLink = `/tenants/${tenantId}/atlases/${atlasId}/folders/${folderId}/journeys/${journeyId}`

      return window.open(
        `mailto:?subject=${t(tKeys.shareJourney.subject)}&body=${t(
          tKeys.shareJourney.hello,
        )}%0D%0A%0D%0A${t(tKeys.shareJourney.subject)}: ${loc.origin}${typeLink}%0D%0A%0D%0A${t(
          tKeys.shareJourney.kindRegards,
        )}`,
      )
    },
    [isAtlas, isFolder, loc.origin, t, tenantId],
  )

  return { handleShareJourney }
}
