import { v4 as uuidv4 } from 'uuid'
import {
  WsCacheEnum,
  WsCreateMessageModel,
  WsDeleteMessageModel,
  WsDuplicateDocumentMessage,
  WsEnhancedErrorMessage,
  WsEnhancedResponseMessage,
  WsErrorMessage,
  WsFoldersMessage,
  WsGetUserStoryBindingsModel,
  WsGraphQlOperationType,
  WsIsAtlasEmptyMessage,
  WsIsFolderEmptyMessage,
  WsLogoutMessage,
  WsMessageType,
  WsMoveMessageModel,
  WsOperationType,
  WsRequestMessage,
  WsResponseMessage,
  WsResponseMessageOkContent,
  WsRestoreMessageModel,
  WsRestoreTenantMessageModel,
  WsRevertMessage,
  WsSearchMessageModel,
  WsSubscribeCallback,
  WsSubscribeCallbacks,
  WsSubscribeDataSpec,
  WsSubscribeMessageModel,
  WsUnsubscribeMessageModel,
  WsVersioningMessage,
  WsVersionListBackMessage,
  WsVersionListForwardMessage,
  WsVersionListMessageModel,
} from '@dis/types/src/wsModels'
import { backendErrorCodeMap } from '@dis/utils/src/beErrorCodeMap'
import { WsChangeMessageModel } from '@dis/types/src/wsChangeModels'
import { BackendErrorCodesUnion } from '@dis/types/src/BackendErrorCodeList'
import { ExtendedAxiosInstance } from '@dis/types/src/api'
import { BE_REST_API_URL } from '@dis/constants'
import { dispatchedActions, store } from '@dis/redux'
import { apiErrorModals } from '@dis/modals/src/apiErrorModals'
import { errorCodeModalCallback } from '@dis/modals/src/errorCodeModal'
import { loggedOutModal } from '@dis/modals/src/loggedOutModal'
import get from 'lodash/get'
import { getTopic } from './utils'
import { WebsocketApi } from './webSocket'

type BufferItem = WsRequestMessage & {
  msgid: string
  networkErrorCounter: number
  time: string
}

type StrictCallback<Response = any> = (
  responseData: Response,
  error?: WsEnhancedErrorMessage['error'],
) => any

type Topic = string
type CallbackId = string

// TODO: Call session refresh on every call
export class Api {
  private static axiosInstance: ExtendedAxiosInstance
  private static buffer: Array<BufferItem> = []
  private static bufferSemaphoreOpened = false
  private static subscriptions: Record<Topic, Record<CallbackId, WsSubscribeCallbacks<any>>> = {}
  private static unsubscriptions: Record<string, () => void> = {}
  private static sentMessages: Record<string, BufferItem> = {}
  private static subscriptionCache: Record<
    string,
    { data: WsEnhancedResponseMessage<any>; updated: number }
  > = {}
  private static subscriptionTimestamps: Record<string, number> = {}
  private static unsubscriptionRefs: Record<string, any> = {}
  private static strictCallbacks: Record<string, StrictCallback> = {}
  private static onSendCallback: (isLogout: boolean) => void = () => {}
  private static useInternalErrorHandlingList: Record<string, boolean> = {}

  private static processSubscriptionError = (
    msgId: string,
    enhancedError: WsEnhancedErrorMessage,
  ): boolean => {
    // Find topic according to the msgId
    const topicEntry = Object.entries(this.subscriptions).find(
      ([_topic, subscriptionObject]) => !!subscriptionObject[msgId],
    )

    if (topicEntry) {
      const topic = topicEntry[0]

      // Notify all the subscribe callbacks in the topic
      Object.values(this.subscriptions[topic]).forEach((callbacks) => {
        if (callbacks[WsOperationType.Subscribe]) {
          callbacks[WsOperationType.Subscribe](undefined, enhancedError)
        }
      })
    }

    return !!topicEntry?.[0]
  }

  private static processMessage = (message: WsResponseMessage) => {
    let operationType: WsOperationType | WsGraphQlOperationType | undefined
    let msgid = ''
    let tenantId = 0
    let messageType: WsMessageType = WsMessageType.Unknown
    let isGraphQlNotification = false

    if ('msgid' in message) {
      msgid = message.msgid
    }

    if ('operationType' in message) {
      operationType = message.operationType
      tenantId = Number(message?.tenantId || 0)
      messageType = message.messageType
    } else if ('metadata' in message) {
      isGraphQlNotification = true
      operationType = message.metadata?.operationType
      tenantId = Number(message.metadata?.tenantId || 0)
      messageType = WsMessageType.Notification
    }

    if ('error' in message && message?.error) {
      const errorMessage = message as WsErrorMessage
      const translatedCode = backendErrorCodeMap[errorMessage.error.code as BackendErrorCodesUnion]

      const enhancedError: WsEnhancedErrorMessage = {
        ...errorMessage,
        error: {
          ...errorMessage.error,
          ...translatedCode,
        },
      }

      console.error('Api - error: ', enhancedError.error)

      if (msgid) {
        // Show central error
        if (this.useInternalErrorHandlingList[msgid]) {
          if (enhancedError.error.code in apiErrorModals) {
            dispatchedActions.centralModalDialog.showModalDialog(
              apiErrorModals[enhancedError.error.code](),
            )
          } else {
            errorCodeModalCallback({
              defaultContent: enhancedError.error.message,
              errorCode: enhancedError.error.code,
              priority: 'errorCode',
            })
          }
        }

        if (this.strictCallbacks[msgid]) {
          this.strictCallbacks[msgid]?.(undefined, enhancedError.error)
        } else if (this.processSubscriptionError(msgid, enhancedError)) {
          return
        } else if (this.sentMessages[msgid]) {
          const sentMessage = this.sentMessages[msgid]

          console.error(
            `Api - error: Request ${JSON.stringify(sentMessage)}, response: ${JSON.stringify(
              enhancedError.error,
            )}`,
          )
        }

        delete this.strictCallbacks[msgid]
        delete this.sentMessages[msgid]

        return
      }

      // Default error handler
      errorCodeModalCallback({
        defaultContent: enhancedError.error.message,
        errorCode: enhancedError.error.code,
      })

      return
    }

    if (!operationType) {
      console.warn('Api - unknown message: ', message)
      return
    }

    // Fill a message id if not filed because the "metadata" message doesn't have message id.
    // This filling has to be placed after error handling block placed above.
    if (!msgid) {
      msgid = Date.now().toString()
    }

    // BE returns response to logout call with messageType "notification" so it's necessary to check who is the real sender
    // in the sent messages.
    if (
      operationType === 'logout' &&
      messageType === WsMessageType.Notification &&
      !this.sentMessages[msgid]
    ) {
      WebsocketApi.close()

      dispatchedActions.centralModalDialog.showModalDialog(loggedOutModal())
      return
    }

    const messageContent: WsResponseMessageOkContent =
      operationType in message
        ? (get(message, operationType) as unknown as WsResponseMessageOkContent)
        : {
            data: 'data' in message ? message.data : undefined,
          }

    if (operationType === 'initialize' && messageType === WsMessageType.Notification) {
      dispatchedActions.api.setConnectionId(messageContent?.id)
      this.bufferSemaphoreOpened = true
      this.processBuffer()
      return
    }

    const data: WsEnhancedResponseMessage<any> = {
      backenable: messageContent?.backenable,
      data: messageContent?.data,
      forwardenable: messageContent?.forwardenable,
      id: messageContent?.id,
      model: messageContent?.model,
      operationType,
      sideloads: 'sideloads' in message ? message?.sideloads : undefined,
      tenantId,
      userId: messageContent.userId,
      version: messageContent.version,
    }

    // Data source for deep copy
    const dataStringified = JSON.stringify(data)

    const topic = isGraphQlNotification
      ? operationType
      : getTopic({
          id: data.id || '',
          model: data.model,
          tenantId,
        })

    if (messageType === WsMessageType.Response) {
      if (this.sentMessages[msgid] && operationType === WsOperationType.Subscribe && data) {
        this.subscriptionCache[topic] = {
          data: JSON.parse(dataStringified),
          updated: Date.now(),
        }

        Object.values(this.subscriptions[topic] || {}).forEach(({ subscribe }) => {
          subscribe?.(JSON.parse(dataStringified))
        })

        delete this.sentMessages[msgid]
      }

      if (this.strictCallbacks[msgid]) {
        this.strictCallbacks[msgid]?.(JSON.parse(dataStringified), undefined)
        delete this.strictCallbacks[msgid]
        return
      }
    }

    // Force downloading new data for topic if a subscription exists
    if (
      messageType === WsMessageType.Notification &&
      ([
        WsOperationType.Change,
        WsOperationType.Create,
        WsOperationType.Delete,
        WsOperationType.Back,
        WsOperationType.Forward,
        WsOperationType.Revert,
      ].includes(operationType as WsOperationType) ||
        isGraphQlNotification) &&
      Object.values(this.subscriptions[topic] || {}).length
    ) {
      Object.values(this.subscriptions[topic] || {}).forEach((callbacks) => {
        if (operationType && operationType in callbacks) {
          callbacks[operationType]?.(JSON.parse(dataStringified))
        }
      })

      return
    }
  }

  public static processBuffer = () => {
    const { isRestApi, websocketConnectionId, isBrowserOnline } = store.getState().api

    if (this.bufferSemaphoreOpened && websocketConnectionId && isBrowserOnline) {
      this.bufferSemaphoreOpened = false

      try {
        while (this.buffer.length) {
          const message = this.buffer.shift()

          if (message) {
            const enhancedMessage = {
              ...message,
              connectionId: websocketConnectionId,
            }

            if (isRestApi) {
              this.axiosInstance
                .post(BE_REST_API_URL, enhancedMessage, {
                  avoidNetworkError: true,
                })
                .then(
                  (response) => {
                    if (
                      !response.data ||
                      response.data?.error ||
                      response.data?.code ||
                      response.errorData
                    ) {
                      if (response.NETWORK_ERROR) {
                        const delay = Math.min((message.networkErrorCounter + 1) * 500, 10 * 500)

                        setTimeout(() => {
                          this.addMessageToBuffer(message, message.networkErrorCounter + 1)
                        }, delay)
                      } else {
                        this.processMessage({
                          ...response.data,
                          error: response.data?.error || response.errorData,
                          msgid: enhancedMessage.msgid,
                        })
                      }
                    } else {
                      // Sometimes msgid in the response is different due to hybrid logic on BE among REST vs. websocket.
                      this.processMessage({
                        ...response.data,
                        msgid: enhancedMessage.msgid,
                      })
                    }
                  },
                  (error) => {
                    console.error('API - REST error: ', error)

                    this.processMessage({
                      error: {
                        code: error?.code || 'serverGeneralError',
                        params: error?.params || undefined,
                      },
                      messageType: WsMessageType.Response,
                      msgid: enhancedMessage.msgid,
                      tenantId: enhancedMessage.tenantId,
                    })
                  },
                )
                .catch((exception) => {
                  console.error('API - REST exception: ', exception)
                  throw exception
                })

              this.sentMessages[enhancedMessage.msgid] = enhancedMessage
            } else {
              WebsocketApi.sendMessage(enhancedMessage)

              this.sentMessages[enhancedMessage.msgid] = enhancedMessage
            }

            const isLogout = WsOperationType.Logout in enhancedMessage
            this.onSendCallback(isLogout)
          }
        }
      } catch (ex) {
        console.error(ex)
      }
    }

    this.bufferSemaphoreOpened = true
  }

  private static addMessageToBuffer = (
    { useInternalErrorHandling, ...restMessage }: WsRequestMessage,
    networkErrorCounter = 0,
    callback?: StrictCallback,
  ) => {
    const msgid = uuidv4()

    const enhancedMsg = {
      ...restMessage,
      msgid: restMessage.msgid || msgid,
      networkErrorCounter,
      time: new Date().toISOString(),
    }

    if (callback) {
      this.strictCallbacks[enhancedMsg.msgid] = callback
    }

    this.useInternalErrorHandlingList[enhancedMsg.msgid] = useInternalErrorHandling ?? true

    this.buffer.push(enhancedMsg)
    this.processBuffer()
  }

  public static subscribe = <Data extends WsSubscribeDataSpec>(
    message: WsSubscribeMessageModel,
    callbacks?: WsSubscribeCallbacks<Data>,
    cache?: `${WsCacheEnum}`,
  ) => {
    const msgId = uuidv4()
    const {
      tenantId,
      subscribe: { id, model },
    } = message

    const topic = getTopic({
      id: id || '',
      model,
      tenantId: tenantId || 0,
    })

    if (!this.subscriptions[topic]) {
      this.subscriptions[topic] = {}
    }

    if (callbacks) {
      if (!this.subscriptions[topic][msgId]) {
        // "as any" helps define empty object
        this.subscriptions[topic][msgId] = {} as any
      }

      Object.entries(callbacks).forEach(([operationType, callback]) => {
        if (this.subscriptions[topic] && this.subscriptions[topic][msgId]) {
          this.subscriptions[topic][msgId][operationType as WsOperationType] = callback
        }
      })
    }

    const now = Date.now()

    // if (this.cache[topic] && now - this.cache[topic].updated < 1_000) {
    const cachedData = this.subscriptionCache[topic]?.data

    // TODO: Implement all types of cache
    if (cachedData && callbacks?.subscribe && cache !== 'no') {
      // Return deep copy of cached data
      callbacks.subscribe(JSON.parse(JSON.stringify(cachedData)))
    }

    // Prevent multiple same subscriptions in a short time
    if (!this.subscriptionTimestamps[topic] || now - this.subscriptionTimestamps[topic] > 1_000) {
      this.subscriptionTimestamps[topic] = now

      clearTimeout(this.unsubscriptionRefs[topic])

      this.addMessageToBuffer({
        ...message,
        msgid: msgId, // without this the processSubscriptionError is not working
      })
    }

    this.unsubscriptions[topic] = () => {
      const unsubscribeMessage: WsUnsubscribeMessageModel = {
        tenantId,
        [WsOperationType.Unsubscribe]: {
          id,
          model,
        },
        useInternalErrorHandling: true,
      }

      // this.addToMessageBuffer(unsubscribeMessage)
      this.addMessageToBuffer(unsubscribeMessage)
    }

    return () => {
      if (this.subscriptions[topic]?.[msgId]) {
        delete this.subscriptions[topic]?.[msgId]
      }

      // Lazy unsubscribe
      clearTimeout(this.unsubscriptionRefs[topic])
      this.unsubscriptionRefs[topic] = setTimeout(() => {
        if (!Object.keys(this.subscriptions[topic] || {}).length) {
          this.unsubscriptions[topic]?.()
          delete this.unsubscriptions[topic]
        }
      }, 1_000)
    }
  }

  public static subscribeGraphqlNotification = <Data>(
    graphqlOperationType: WsGraphQlOperationType,
    callback: WsSubscribeCallback<Data>,
  ) => {
    const msgId = uuidv4()

    const topic = graphqlOperationType

    if (!this.subscriptions[topic]) {
      this.subscriptions[topic] = {}
    }

    if (!this.subscriptions[topic][msgId]) {
      // "as any" helps define empty object
      this.subscriptions[topic][msgId] = {} as any
    }

    this.subscriptions[topic][msgId][graphqlOperationType] = callback

    return () => {
      if (this.subscriptions[topic]?.[msgId]) {
        delete this.subscriptions[topic]?.[msgId]
      }
    }
  }

  public static sendChangeMessage = (message: WsChangeMessageModel, callback?: StrictCallback) =>
    this.addMessageToBuffer(message, 0, callback)

  public static sendCreateMessage = (message: WsCreateMessageModel, callback?: StrictCallback) =>
    this.addMessageToBuffer(message, 0, callback)

  public static sendDeleteMessage = (message: WsDeleteMessageModel, callback?: StrictCallback) =>
    this.addMessageToBuffer(message, 0, callback)

  public static sendRestoreMessage = (message: WsRestoreMessageModel, callback?: StrictCallback) =>
    this.addMessageToBuffer(message, 0, callback)

  public static sendRestoreTenantMessage = (
    message: WsRestoreTenantMessageModel,
    callback?: StrictCallback,
  ) => this.addMessageToBuffer(message, 0, callback)

  public static sendMoveMessage = (message: WsMoveMessageModel, callback?: StrictCallback) =>
    this.addMessageToBuffer(message, 0, callback)

  public static sendGetFolders = (message: WsFoldersMessage, callback?: StrictCallback) =>
    this.addMessageToBuffer(message, 0, callback)

  public static sendEmptyContentAtlas = (
    message: WsIsAtlasEmptyMessage,
    callback?: StrictCallback,
  ) => this.addMessageToBuffer(message, 0, callback)

  public static sendEmptyContentFolder = (
    message: WsIsFolderEmptyMessage,
    callback?: StrictCallback,
  ) => this.addMessageToBuffer(message, 0, callback)

  public static sendRevert = (message: WsRevertMessage, callback?: StrictCallback) =>
    this.addMessageToBuffer(message, 0, callback)

  public static sendVersionlist = (message: WsVersionListMessageModel, callback?: StrictCallback) =>
    this.addMessageToBuffer(message, 0, callback)

  public static sendVersion = (message: WsVersioningMessage, callback?: StrictCallback) =>
    this.addMessageToBuffer(message, 0, callback)

  public static sendSearchMessage = (
    message: WsSearchMessageModel,
    callback: StrictCallback<WsEnhancedResponseMessage>,
  ) => this.addMessageToBuffer(message, 0, callback)

  public static sendVersionlistBack = (
    message: WsVersionListBackMessage,
    callback?: StrictCallback,
  ) => this.addMessageToBuffer(message, 0, callback)

  public static sendVersionlistForward = (
    message: WsVersionListForwardMessage,
    callback?: StrictCallback,
  ) => this.addMessageToBuffer(message, 0, callback)

  public static sendGetUserStoryBindings = (
    message: WsGetUserStoryBindingsModel,
    callback?: StrictCallback,
  ) => this.addMessageToBuffer(message, 0, callback)

  public static sendDuplicateDocument = (
    message: WsDuplicateDocumentMessage,
    callback?: StrictCallback,
  ) => this.addMessageToBuffer(message, 0, callback)

  public static sendLogout = (message: WsLogoutMessage) => {
    // Add message to buffer
    this.addMessageToBuffer(message)

    // Force sending immediately because logout is a high-priority message
    // this.processBuffer()
  }

  public static setAxiosInstance = (axiosInstance: ExtendedAxiosInstance) => {
    this.axiosInstance = axiosInstance
  }

  public static setOnSendCallback = (onSendCallback: (isLogout: boolean) => void) => {
    this.onSendCallback = onSendCallback
  }

  public static clearAll = () => {
    console.warn('TODO: Api - deleting cache not implemented!')

    Object.values(this.unsubscriptionRefs).forEach((ref) => clearTimeout(ref))

    Object.values(this.unsubscriptions).forEach((callback) => callback())
    this.unsubscriptions = {}

    WebsocketApi.close()
    this.subscriptionCache = {}
  }

  static {
    WebsocketApi.setOnMessageCallback(this.processMessage)
  }
}
