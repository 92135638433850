import { useParams } from 'react-router'

export const useTypedParams = () => {
  const { atlasId, folderId, personaId, tenantId, journeyId, templateId } = useParams()

  return {
    // TODO: add more types
    atlasId: atlasId ? Number(atlasId) : undefined,
    folderId: folderId ? Number(folderId) : undefined,
    journeyId: journeyId ? Number(journeyId) : undefined,
    personaId: personaId ? Number(personaId) : undefined,
    templateId: templateId ? Number(templateId) : undefined,
    tenantId: tenantId ? Number(tenantId) : undefined,
  }
}
