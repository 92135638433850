import React, { useMemo } from 'react'
import { styles } from './styles'

interface LoadingAnimationProps {
  innerStyle?: React.CSSProperties
  isAnimated?: boolean
  outerStyle?: React.CSSProperties
}

const defaultOuterStyle: React.CSSProperties = {
  display: 'flex',
  margin: 'auto',
  minHeight: '9vw',
  width: '15vw',
}
const defaultInnerStyle: React.CSSProperties = {
  minHeight: '100%',
  position: 'relative',
  width: '100%',
}

export const LoadingAnimation = ({
  outerStyle = {},
  innerStyle = {},
  isAnimated = true,
}: LoadingAnimationProps) => {
  const { oStyle, iStyle } = useMemo(
    () => ({
      iStyle: {
        ...defaultInnerStyle,
        ...innerStyle,
      },
      oStyle: {
        ...defaultOuterStyle,
        ...outerStyle,
      },
    }),
    [innerStyle, outerStyle],
  )

  return (
    <div style={oStyle}>
      <div style={iStyle}>
        <div className={styles.animationRing1(isAnimated)} />
        <div className={styles.animationRing2(isAnimated)} />
        <div className={styles.animationRing3(isAnimated)} />
      </div>
    </div>
  )
}
