import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Box } from '@mui/material'
import { Modal, Paragraph } from '@dis/components'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { Select, useForm, yup } from '@dis/form'
import { useDuplicateDocument } from '@dis/hooks/src/useDuplicateDocument'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { DuplicateJourneyForm } from '@dis/types/src/forms'
import { useNavTo, useTenantList } from '@dis/hooks'
import { selectUserRole } from '@dis/redux/src/security/selectors'
import { UserRole } from '@dis/types/src/UsersAndRoles'
import { selectJourneyTenantLimits } from '@dis/redux/src/journeys/journeysSelectors'
import { MAX_NAME_LENGTH } from '@dis/constants'
import { useAtlasesAndFolders } from '@dis/hooks/src/useAtlasesAndFolders'
import { JourneyLocation } from '@dis/types/src/JourneyTypes'
import { theme } from '@dis/styles'
import { CreateDuplicateJourneyTooltip } from './CreateDuplicatJourneyTooltip'
import { CreateOrDuplicateJourneyFormPart } from '../journeyModalModules/CreateOrDuplicateJourneyFormPart'
import { getCreateOrDuplicateJourneyValidationPart } from '../journeyModalModules/utils'

type DataProps = {
  id: string
}

type Props = {
  handleClose: VoidFunction
  journeyId?: number
  journeyName?: string
  open: boolean
  refetch?: VoidFunction
}

export const DuplicateJourneyModal = ({ open, handleClose, journeyId, journeyName }: Props) => {
  const wasOpenedAlready = useRef(false)
  wasOpenedAlready.current = wasOpenedAlready.current || open

  const userRole = useAppSelector(selectUserRole)
  const currentTenantId = useAppSelector(selectSelectedTenantId)
  const tenantLimits = useAppSelector(selectJourneyTenantLimits)

  const maxJourneyNameLength = tenantLimits?.maxlen.journey.name || MAX_NAME_LENGTH

  const { t } = useTranslation()

  const { navigate } = useNavTo()
  const duplicateTargetRef = useRef<JourneyLocation>()

  const onDataCallback = useCallback(
    (data: DataProps) => {
      const journeyId = parseInt(data.id)

      // if the notification contains a navigation link to another journey
      const onClickOpenJourney = () => {
        dispatchedActions.journeys.setSelectedJourneyId(journeyId)

        const currentDuplicateTarget = duplicateTargetRef.current
        if (currentDuplicateTarget) {
          const folderId = currentDuplicateTarget.folderId

          /**
           * Set new tenant ID to redux manually to avoid some errors with subscriptions to old tenant ID because
           * redux and JavaScript is asynchronous.
           */
          dispatchedActions.tenants.setSelectedTenantId(currentDuplicateTarget.tenantId)

          if (folderId) {
            navigate('journeyFolder', {
              atlasId: currentDuplicateTarget.atlasId,
              folderId,
              journeyId: journeyId,
              tenantId: currentDuplicateTarget.tenantId,
            })
          } else {
            navigate('journey', {
              atlasId: currentDuplicateTarget.atlasId,
              journeyId: journeyId,
              tenantId: currentDuplicateTarget.tenantId,
            })
          }
        }
      }

      dispatchedActions.centralNotification.showNotification({
        content: (
          <Paragraph>
            {t(tKeys.notifications.notificationJourneySuccessfully)}{' '}
            <a onClick={onClickOpenJourney} href="#">
              {t(tKeys.notifications.notificationEndDuplicated)}
            </a>
          </Paragraph>
        ),
        displayTimeInMs: 5000,
      })
    },
    [navigate, t],
  )
  const { send } = useDuplicateDocument({ onData: onDataCallback })

  const validationSchema = useMemo(
    (): yup.ObjectSchema<DuplicateJourneyForm> =>
      yup.object().shape({
        ...getCreateOrDuplicateJourneyValidationPart({ maxJourneyNameLength }),
        tenantId: yup.number().required(),
      }),
    [maxJourneyNameLength],
  )

  const {
    control: formControl,
    watch,
    handleSubmit,
    setValue: setFormValue,
    unregister,
  } = useForm<DuplicateJourneyForm>({
    defaultValues: {
      tenantId: currentTenantId,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    validationSchema,
  })

  const atlasId = watch('atlasId')
  const formTenantId = watch('tenantId')

  const tenantList = useTenantList({ semaphore: wasOpenedAlready.current })
  const {
    atlasOptions,
    folderOptions,
    refetch: refetchAtlasOptions,
  } = useAtlasesAndFolders({ atlasId, forceTenantId: formTenantId })

  const onSubmitForm = handleSubmit((formData) => {
    const {
      tenantId: targetTenantId,
      atlasId: targetAtlasId,
      folderId: targetFolderId,
      name,
    } = formData

    if (currentTenantId && targetTenantId && targetAtlasId && journeyId) {
      duplicateTargetRef.current = {
        atlasId: targetAtlasId,
        folderId: targetFolderId,
        journeyId,
        tenantId: targetTenantId,
      }

      send({
        journeyId,
        name,
        targetAtlasId,
        targetFolderId,
        targetTenantId,
      })
      handleClose()
    } else {
      throw new Error(`Missing some required form properties! ${formData}`)
    }
  })

  const tenantTypeList = useMemo(() => {
    const tenantTemplateId = tenantList?.tenantList?.find(({ istemplate }) => istemplate)?.id

    const ret: Array<{ label: string; value: number }> = [
      {
        label: t(tKeys.duplicateJourneyTenantType.current),
        value: currentTenantId || 0,
      },
    ]

    if (userRole === UserRole.KpmgAdmin && tenantTemplateId) {
      ret.push({
        label: t(tKeys.duplicateJourneyTenantType.template),
        value: tenantTemplateId,
      })
    }

    return ret
  }, [currentTenantId, t, tenantList, userRole])

  /**
   * Reset some form items when selected tenant changed
   */
  const handleChangeTenant = useCallback(() => {
    unregister('atlasId')
    unregister('folderId')
  }, [unregister])

  useEffect(() => {
    setFormValue('name', journeyName + '-copy')
  }, [journeyName, setFormValue])

  return (
    <Modal
      tooltip={<CreateDuplicateJourneyTooltip />}
      isOpen={open}
      title={t(tKeys.duplicateJourney)}
      onCrossBtnClick={handleClose}
      primaryBtnText={t(tKeys.common.submit)}
      onPrimaryBtnClick={onSubmitForm}
      height="normal"
      width="normal">
      <Box gap={theme.spacing(6)} display="flex" flexDirection="column">
        <CreateOrDuplicateJourneyFormPart
          atlasOptions={atlasOptions}
          control={formControl}
          folderOptions={folderOptions}
          forceTenantId={formTenantId}
          refetchAtlasOptions={refetchAtlasOptions}
          setValue={setFormValue}
          tenantSelect={
            <Select
              disabled={userRole !== UserRole.KpmgAdmin}
              fixedLabel
              formControl={formControl}
              fullWidth
              label={t(tKeys.common.tenant)}
              name="tenantId"
              options={tenantTypeList}
              onChange={handleChangeTenant}
              required
              variant="outlined"
            />
          }
        />
      </Box>
    </Modal>
  )
}
