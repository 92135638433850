import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { State } from './types'

const initialState: State = {
  azureAuthorityUrl: '',
  beUrl: '',
  clientId: '',
  isMobileDevice: false,
}

export const generalSlice = createSlice({
  initialState,
  name: 'general',
  reducers: {
    setConfigData: (
      state,
      {
        payload: { azureAuthorityUrl, beUrl, clientId },
      }: PayloadAction<Omit<State, 'isMobileDevice'>>,
    ) => {
      state.clientId = clientId
      state.beUrl = beUrl
      state.azureAuthorityUrl = azureAuthorityUrl
    },
    setIsMobileDevice: (state, { payload }: PayloadAction<boolean>) => {
      state.isMobileDevice = payload
    },
  },
})
