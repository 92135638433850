import { RootState } from '@dis/redux'

export const selectSelectedPersonaId = (state: RootState) => {
  return state.personas.personaId
}

export const selectIsNewPersona = (state: RootState) => {
  return state.personas.isNew
}

export const selectPersonaCallback = (state: RootState) => {
  return state.personas.callback
}

export const selectIsPersonaBackEnable = (state: RootState) => state.personas.isBackEnable

export const selectIsPersonaForwardEnable = (state: RootState) => state.personas.isForwardEnable

export const selectPersonaSelectedVersionData = (state: RootState) =>
  state.personas.personaSelectedVersionData

export const selectPersonaVersion = (state: RootState) => state.personas.version
