import { useCallback, useMemo } from 'react'
import { SelectProps } from '@dis/components'
import { GroupsManagementAccessLevelEnum } from '@dis/types/src/GroupsManagement'
import { useTenant } from './useSubscribe'

type Props = {
  atlasId?: number
  forceTenantId?: number
}

export const useAtlasesAndFolders = ({ atlasId, forceTenantId }: Props) => {
  /**
   * The useTenant hook contains atlases data same as in the tenant redux store.
   * Calling the useTenant refreshes data in the redux store also.
   */
  const { tenantData, loading, refetch } = useTenant({ forceTenantId })

  const atlases = tenantData?.atlases

  const filteredAtlases = useMemo(
    () =>
      atlases?.filter(
        ({ accesslevel }) => accesslevel === GroupsManagementAccessLevelEnum.Editable,
      ),

    [atlases],
  )

  const atlasOptions = useMemo((): SelectProps['options'] => {
    return (
      (filteredAtlases ?? []).map(({ id, name }) => ({
        disabled: false,
        label: name || '',
        value: id,
      })) || []
    )
  }, [filteredAtlases])

  const folderOptions = useMemo((): SelectProps['options'] => {
    if (!atlasId || !atlases?.find(({ id }) => id === atlasId)) {
      return []
    }

    const folders = (atlases ?? []).find(({ id }) => id === atlasId)?.folders ?? []

    return folders.map(({ id, name }) => ({
      disabled: false,
      // TODO: add default atlas name
      label: name ?? '',
      value: id,
    }))
  }, [atlasId, atlases])

  const atlasName = useMemo(() => {
    if (atlasId && atlases?.length) {
      return atlases.find(({ id }) => id === atlasId)?.name
    }

    return
  }, [atlasId, atlases])

  const getFolderName = useCallback(
    (folderId?: number) => {
      if (atlasId && folderId) {
        const atlas = atlases?.find(({ id }) => id === atlasId)
        const folder = atlas?.folders?.find(({ id }) => id === folderId)

        return folder?.name
      }

      return
    },
    [atlasId, atlases],
  )

  return {
    atlasName,
    atlasOptions,
    folderOptions,
    getFolderName,
    loading,
    refetch,
  }
}
