import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { OrderDirection } from '@dis/components/src/table/types'

export type TableType = 'backlog' // add e.g. channels, etc.

export type TableReduxSortItem = {
  direction: OrderDirection
  item: string
}

type TableReducerState = {
  sort: Record<TableType, TableReduxSortItem>
}

const initialState: TableReducerState = {
  sort: {
    backlog: {
      direction: 'asc',
      item: 'id',
    },
  },
}

export const tableSlice = createSlice({
  initialState,
  name: 'table',
  reducers: {
    setSort: (state, action: PayloadAction<{ type: TableType; value: TableReduxSortItem }>) => {
      state.sort[action.payload.type] = action.payload.value
    },
  },
})
