import React, { ReactNode, SyntheticEvent, useCallback, useRef } from 'react'
import { Button, type ButtonProps, Menu } from '@mui/material'
import { useAnchor } from '@dis/hooks/src/useAnchor'
import { colors, cx } from '@dis/styles'
import { TypographyKeys, getTypographyProp } from '@dis/styles/src/theme'
import ThreeDotsIcon from '@dis/assets/src/icons/ThreeDotsIcon.svg'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { useAppSelector } from '@dis/redux'
import { Item } from './Item'
import { styles } from './styles'
import { SvgImage } from '../SvgImage/SvgImage'
import { MenuItem } from './types'

type Props = {
  header?: ReactNode
  icon?: string
  iconColor?: string
  iconSize?: TypographyKeys
  items: Array<MenuItem>
  label?: string | ReactNode
  menuOnLeft?: boolean
  onVisibilityChanged?: (visible: boolean) => void
  submenuOnLeft?: boolean
}

// TODO: Create a better name for this component.
// TODO: Use this component in journeys so replace the old one.
export const PopoverWithAnchor = ({
  header,
  items,
  label,
  icon,
  iconColor,
  iconSize = 'paragraph.large',
  menuOnLeft,
  onVisibilityChanged,
  submenuOnLeft,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  const { anchorEl, handleClose, handleOpen } = useAnchor()

  const isRtl = useAppSelector(selectIsLanguageRtl)

  const handleOpenPopover = useCallback<NonNullable<ButtonProps['onClick']>>(
    (event) => {
      event.stopPropagation()
      handleOpen(event)
      onVisibilityChanged?.(true)
    },
    [handleOpen, onVisibilityChanged],
  )

  const handleCloseWrapper = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation()

      handleClose()
    },
    [handleClose],
  )

  return (
    <>
      <Button onClick={handleOpenPopover} className={styles.mainButton.root}>
        {!!icon && (
          <span
            className={cx({
              [styles.mainButton.iconWithMargin]: !!label,
            })}>
            <SvgImage
              src={icon}
              color={iconColor || colors.gray.gray100}
              className={styles.mainButton.icon(getTypographyProp(iconSize, 'fontSize'))}
            />
          </span>
        )}
        {!icon && (
          <div className={styles.mainButton.iconWrapper}>
            <SvgImage src={ThreeDotsIcon} />
          </div>
        )}
      </Button>

      <Menu
        className={styles.menu}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseWrapper}
        anchorOrigin={{
          horizontal: isRtl ? 'right' : 'left',
          vertical: 'top',
        }}
        transformOrigin={
          menuOnLeft
            ? {
                horizontal: isRtl ? 'left' : 'right',
                vertical: 'top',
              }
            : undefined
        }>
        <div ref={wrapperRef}>
          {header}
          {items.map((item) => (
            <Item
              handleClose={handleClose}
              key={`${item.navigateTo}_${item.label}`}
              menuItem={item}
              opened={!!anchorEl}
              submenuOnLeft={submenuOnLeft}
            />
          ))}
        </div>
      </Menu>
    </>
  )
}
