import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { PersonaDetailType } from '@dis/types/src/PersonaTypes'

type PersonasReducerState = {
  callback: {
    atlasId?: number
    folderId?: number
    journeyId?: number
  }
  isBackEnable: boolean
  isForwardEnable: boolean
  isNew: boolean
  personaId?: number
  personaSelectedVersionData?: PersonaDetailType
  version?: number
}

const initialState: PersonasReducerState = {
  callback: {},
  isBackEnable: false,
  isForwardEnable: false,
  isNew: false,
  personaId: undefined,
  personaSelectedVersionData: undefined,
}

export const personasSlice = createSlice({
  initialState,
  name: 'personas',
  reducers: {
    init: () => initialState,
    setCallback: (state, action: PayloadAction<PersonasReducerState['callback']>) => {
      state.callback = action.payload
    },
    setIsBackEnable: (state, action: PayloadAction<boolean>) => {
      state.isBackEnable = action.payload
    },
    setIsForwardEnable: (state, action: PayloadAction<boolean>) => {
      state.isForwardEnable = action.payload
    },
    setNewPersona: (state) => {
      state.isNew = true
    },
    setPersonaId: (state, action: PayloadAction<number | undefined>) => {
      state.personaId = action.payload
    },
    setPersonaSelectedVersionData: (
      state,
      action: PayloadAction<PersonaDetailType | undefined>,
    ) => {
      state.personaSelectedVersionData = action.payload
    },
    setPersonaVersion: (state, action: PayloadAction<number | undefined>) => {
      state.version = action.payload
    },
  },
})
