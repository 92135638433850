import { useEffect, useState } from 'react'
import { AxiosInstances } from '@dis/api'
import { configJsonUrl } from '@dis/api/src/endpoints'

export const useGetConfigJSONQuery = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<unknown>()
  const [error, setError] = useState<unknown>()

  useEffect(() => {
    AxiosInstances.localAxiosInstance.get(configJsonUrl).then(
      (response) => {
        setData(response.data)
        setIsLoading(false)
      },
      (error) => {
        setIsLoading(false)
        console.error('Cannot load config.json')
        setError(error)
      },
    )
  }, [])

  return {
    data,
    error,
    isLoading,
  }
}
