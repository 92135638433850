import { useCallback } from 'react'
import { useLocation } from 'react-router'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { APP_DOMAIN } from '@dis/constants'
import { useAppSelector } from '@dis/redux'
import { selectTenantData } from '@dis/redux/src/tenants/tenantsSelectors'

export const useSendEmail = () => {
  const location = useLocation()
  const { t } = useTranslation()

  const data = useAppSelector(selectTenantData)

  const pathname = location.pathname

  const handleSendEmail = useCallback(() => {
    return window.open(
      `mailto:${data?.feedback}?subject=${t(
        tKeys.sendEmail.subject,
      )}&body=${APP_DOMAIN}${pathname}`,
    )
  }, [data?.feedback, pathname, t])

  return { handleSendEmail }
}
