import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { SearchResults } from './searchTypes'

type SearchReducerState = {
  results?: SearchResults
  searchWord?: string
}

const initialState: SearchReducerState = {
  results: {
    atlases: [],
    contents: [],
    folders: [],
    journeys: [],
    personas: [],
  },
  searchWord: undefined,
}
export const searchSlice = createSlice({
  initialState,
  name: 'search',
  reducers: {
    init: () => initialState,
    setSearchResults: (state, action: PayloadAction<SearchResults | undefined>) => {
      state.results = action.payload
    },
    setSearchWord: (state, action: PayloadAction<string>) => {
      state.searchWord = action.payload
    },
  },
})
