import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Popover, Tooltip } from '@mui/material'
import { useAnchor } from '@dis/hooks/src/useAnchor'
import { useAppSelector } from '@dis/redux'
import { selectIsLanguageRtl } from '@dis/redux/src/user/selectors'
import { colors } from '@dis/styles'
import { selectSelectedTenantId } from '@dis/redux/src/tenants/tenantsSelectors'
import { selectSelectedJourneyId } from '@dis/redux/src/journeys/journeysSelectors'
import { selectUserOid } from '@dis/redux/src/security/selectors'
import { useGetUserActivityPresenceList } from '@dis/hooks/src/useGetUserActivityPresenceList'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { UserActivityPresenceStatus } from '@dis/api/src/graphqlClient/gql/__generated__/QueriesGetUserActivityPresenceListQuery.graphql'
import { type TFunction } from 'i18next'
import { JOURNEY_ACTIVE_USERS_PREVIEW_LENGTH } from '@dis/constants'
import { getClearName, getSignature } from '@dis/utils/src/users'
import { cx } from '@emotion/css'
import { Paragraph } from '@dis/components'
import { styles } from './styles'

/**
 * @timestamp - seconds
 */
type UserListItem = {
  activity: UserActivityPresenceStatus
  email: string
  id: string
  name: string
  signature: string
  timestamp: number
}

const countColor = (
  { activity, name, timestamp }: UserListItem,
  t: TFunction,
  desc1tKey: string,
  desc2tKey: string,
) => {
  let borderColor: string = colors.kpmg.cobaltLight

  let desc = t(desc1tKey, { activity, name })

  if ((activity === 'editing' || activity === 'viewing') && timestamp < 30) {
    borderColor = colors.semantic.green
  } else if ((activity === 'editing' || activity === 'viewing') && timestamp < 30 * 60) {
    borderColor = colors.gray.gray40
  } else {
    const tmp = Math.floor(timestamp / 60 / 30)
    const timeAmount = tmp * 30

    desc = t(desc2tKey, { name, timeAmount })
  }

  return {
    borderColor,
    desc,
  }
}

export const ActiveUserList = () => {
  const [users, setUsers] = useState<UserListItem[]>([])
  // Periodically re-render content to keep colors up-to-date
  // Don't use componentKey as a key for whole component otherwise the popover component fails.
  const [componentKey, setComponentKey] = useState(Date.now())

  const wrapperRef = useRef<HTMLDivElement>(null)
  const intervalRef = useRef<ReturnType<typeof setInterval>>()

  const isRtl = useAppSelector(selectIsLanguageRtl)
  const tenantId = useAppSelector(selectSelectedTenantId)
  const journeyId = useAppSelector(selectSelectedJourneyId)
  const userId = useAppSelector(selectUserOid)

  const { t } = useTranslation()

  const { anchorEl, handleClose, handleOpen } = useAnchor()

  const { data, refetch } = useGetUserActivityPresenceList({
    skip: !tenantId || !userId || !journeyId,
  })

  const firstPart = users.slice(0, JOURNEY_ACTIVE_USERS_PREVIEW_LENGTH)
  const secondPart = users.slice(JOURNEY_ACTIVE_USERS_PREVIEW_LENGTH)

  // Keep componentKey prop in the dependency array to refresh user list
  useEffect(() => {
    clearInterval(intervalRef.current)

    const enhancedUserArray: UserListItem[] = (
      data?.items?.map(
        ({ userInfo: { email, name, id }, metadata: { lastHeartbeat, status } }): UserListItem => {
          const timestamp = (Date.now() - new Date(lastHeartbeat).getTime()) / 1000
          const clearName = getClearName(name)

          return {
            activity: status,
            email,
            id,
            name: clearName,
            signature: getSignature(name),
            timestamp,
          }
        },
      ) || []
    )
      .filter((item) => item.id !== userId)
      .sort((a, b) => a.timestamp - b.timestamp)

    setUsers(enhancedUserArray)

    intervalRef.current = setInterval(() => {
      setComponentKey(Date.now())
    }, 10_000)
  }, [componentKey, data?.items, userId])

  // Call api twice after component mount to load fresh data because sending own status using useSetJourneyActivity
  // in journey takes some time and the previous data is missing information about my presence.
  useEffect(() => {
    const intervalRef = setTimeout(refetch, 2_000)

    return () => {
      clearTimeout(intervalRef)
    }
  }, [refetch])

  return (
    <div className={styles.root}>
      <Fragment key={`bubbleList_${componentKey}`}>
        {firstPart.map((userObj, index) => {
          const { name, signature } = userObj

          const { borderColor, desc } = countColor(
            userObj,
            t,
            tKeys.journeys.activeUserList.currentStatus,
            tKeys.journeys.activeUserList.lastViewed,
          )

          return (
            <Tooltip
              arrow
              key={name}
              title={desc}
              classes={{ arrow: styles.tooltip, popper: styles.tooltip }}>
              <div
                key={name}
                className={styles.bubble.default}
                style={{ borderColor, zIndex: firstPart.length - index }}>
                {signature}
              </div>
            </Tooltip>
          )
        })}
      </Fragment>

      <div>
        {secondPart.length > 0 && (
          <div
            className={cx(styles.bubble.default, {
              [styles.bubble.blue]: !!anchorEl,
            })}
            onClick={handleOpen}>
            +{secondPart.length}
          </div>
        )}

        <Popover
          className={cx(styles.popover.root, {
            [styles.popover.rtl]: isRtl,
          })}
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: isRtl ? 'left' : 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: isRtl ? 'left' : 'right',
            vertical: 'top',
          }}
          onClose={handleClose}>
          <div ref={wrapperRef} className={styles.list} key={`popoverList_${componentKey}`}>
            {secondPart.map((userObj) => {
              const { name, signature } = userObj

              const { borderColor, desc } = countColor(
                userObj,
                t,
                tKeys.journeys.activeUserList.currentStatusShort,
                tKeys.journeys.activeUserList.lastViewedShort,
              )

              return (
                <div key={name} className={styles.listItem.root}>
                  <div className={styles.listItem.leftColumn}>
                    <div key={name} className={styles.bubble.default} style={{ borderColor }}>
                      {signature}
                    </div>
                  </div>
                  <div className={styles.listItem.rightColumn}>
                    <Paragraph variant="small" fontWeight="bold">
                      {name}
                    </Paragraph>
                    <Paragraph variant="tiny" fontWeight="regular">
                      {desc.toLowerCase()}
                    </Paragraph>
                  </div>
                </div>
              )
            })}
          </div>
        </Popover>
      </div>
    </div>
  )
}
