import { FC } from 'react'
import { Grid } from '@mui/material'
import { BasicInfo } from '@dis/types/src/PersonaTypes'
import { Paragraph } from '@dis/components'
import { usePersonaDefaultTitles } from '@dis/hooks'
import { styles } from './styles'
import { MAX_PERSONA_TEXTS_LENGTH } from '../constants'

type Props = { basic: Array<BasicInfo> }

export const PersonaInfo: FC<Props> = ({ basic }) => {
  const { personaTitles } = usePersonaDefaultTitles()

  return (
    <Grid container gap={2}>
      {basic
        .map(({ value, position, title }, i) => {
          return (
            personaTitles[i] && (
              <Grid key={position} container justifyContent="space-between">
                <Grid item xs={12} sm={6}>
                  <Paragraph
                    className={styles.text}
                    variant="small"
                    fontWeight="bold"
                    title={title?.length || 0 > MAX_PERSONA_TEXTS_LENGTH ? title : undefined}>
                    {title ?? personaTitles[i]}
                  </Paragraph>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paragraph
                    variant="small"
                    className={styles.text}
                    title={value?.length || 0 > MAX_PERSONA_TEXTS_LENGTH ? value : undefined}>
                    {value || '-'}
                  </Paragraph>
                </Grid>
              </Grid>
            )
          )
        })
        .filter((value) => value !== null)}
    </Grid>
  )
}
