import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, CheckboxProps, NotificationModal, Paragraph } from '@dis/components'
import { tKeys } from '@dis/languages'
import { typography } from '@dis/styles'
import { useAppSelector } from '@dis/redux'
import { selectCanDeleteTenant } from '@dis/redux/src/security/selectors'
import { useDeleteTenant } from '@dis/hooks/src/useDelete'
import { styles } from './styles'

type TenantProps = {
  handleClose: VoidFunction
  isTemplate?: boolean
  name?: string
  open?: boolean
  tenantId?: number
}

export const DeleteTenantModal = ({
  name,
  isTemplate,
  tenantId,
  handleClose,
  open,
}: TenantProps) => {
  const [permanentDelete, setPermanentDelete] = useState(false)
  const canDeleteTenant = useAppSelector(selectCanDeleteTenant)
  const { t } = useTranslation()

  const { sendDelete } = useDeleteTenant()

  const onClickSendDelete = useCallback(() => {
    handleClose()

    if (!isTemplate && canDeleteTenant && tenantId) {
      sendDelete(tenantId, permanentDelete)
    }

    setPermanentDelete(false)
  }, [canDeleteTenant, handleClose, isTemplate, permanentDelete, sendDelete, tenantId])

  const onCheckboxClick = useCallback<NonNullable<CheckboxProps['onClick']>>(() => {
    setPermanentDelete((state) => !state)
  }, [setPermanentDelete])

  return (
    <NotificationModal
      isOpen={open}
      primaryBtnText={t(tKeys.common.delete)}
      onPrimaryBtnClick={onClickSendDelete}
      secondaryBtnText={t(tKeys.common.cancel)}
      onSecondaryBtnClick={handleClose}
      severity="warning"
      onCrossBtnClick={handleClose}
      title={t(tKeys.deleteModal.tenant.deleteTenant)}>
      <>
        <Paragraph
          tKey={tKeys.deleteModal.tenant.doYouWantToDeleteTenant}
          tValues={{
            name: name,
          }}
          variant="medium"
        />
        <div className={styles.modalCheckbox}>
          <Checkbox
            inlineLabel={t(tKeys.deleteModal.tenant.permanentDelete)}
            inlineLabelSx={typography.paragraph.body}
            onClick={onCheckboxClick}
            value={permanentDelete}
          />
        </div>
      </>
    </NotificationModal>
  )
}
