import { useCallback, useRef } from 'react'
import { TextField, type TextFieldProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@dis/assets/src/icons/SearchIcon.svg'
import { tKeys } from '@dis/languages'
import { cx } from '@dis/styles'
import { TypographyKeys, getTypographyProp } from '@dis/styles/src/theme'
import { v4 as uuidv4 } from 'uuid'
import { styles } from './styles'
import { SvgImage } from '../SvgImage/SvgImage'

export type SearchBarProps = {
  className?: TextFieldProps['className']
  fontSize?: TypographyKeys
  inputValue: TextFieldProps['value']
  onBlur?: TextFieldProps['onBlur']
  onChange?: TextFieldProps['onChange']
  onChangeValue?: (value: string) => void
  onKeyUp?: TextFieldProps['onKeyUp']
  placeholder?: string
  size?: 'small' | 'medium' | 'large'
  sx?: TextFieldProps['sx']
  width?: string
}

export const SearchBar = ({
  className,
  fontSize = 'paragraph.body',
  inputValue,
  onBlur,
  onChange,
  onChangeValue,
  onKeyUp,
  placeholder,
  size = 'small',
  sx,
  width = '140px',
}: SearchBarProps) => {
  const name = useRef(uuidv4())

  const { t } = useTranslation()

  const onChangeWrapper = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      const value = e.target.value

      onChangeValue?.(value)

      onChange?.(e)
    },
    [onChange, onChangeValue],
  )

  return (
    <TextField
      style={{ fontSize: getTypographyProp(fontSize, 'fontSize'), width }}
      className={cx(
        styles.container,
        {
          [styles.containerSmall]: size === 'small',
          [styles.containerMedium]: size === 'medium',
          [styles.containerLarge]: size === 'large',
        },
        className,
      )}
      autoComplete="off"
      InputProps={{
        startAdornment: <SvgImage src={SearchIcon} />,
      }}
      inputProps={{
        autoComplete: 'off',
        name: name.current,
        style: { fontSize: getTypographyProp(fontSize, 'fontSize') },
      }}
      InputLabelProps={{
        style: { fontSize: getTypographyProp(fontSize, 'fontSize') },
      }}
      value={inputValue}
      placeholder={placeholder ?? t(tKeys.applicationMenu.searching)}
      focused
      onChange={onChangeWrapper}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      sx={sx}
    />
  )
}
