import { css } from '@emotion/css'
import { colors, theme } from '@dis/styles'

export const styles = {
  animation: {
    height: '50vh',
    minHeight: 0,
    width: '50vh',
  },
  background: css`
    background: rgba(33, 33, 33, 0.4);
  `,
  isVisible: css`
    opacity: 1;
    z-index: ${theme.zIndex.modal - 2};
    transition:
      z-index 0s linear 0s,
      opacity 0.5s linear 0s;
  `,
  loader: css`
    color: ${colors.gray.gray0};
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  `,
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    z-index: -100;
    background-color: ${colors.transparent};
    transition:
      opacity 0.5s linear 0s,
      z-index 0s linear 0.5s;
  `,
  text: css`
    color: ${colors.gray.gray0};
    white-space: nowrap;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  `,
  textBox: css`
    color: ${colors.gray.gray0};
    .MuiCircularProgress-root {
      height: 2rem !important;
      width: 2rem !important;
    }
  `,
}
