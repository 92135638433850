import {
  type ListenerEffectAPI,
  type TypedStartListening,
  configureStore,
  createListenerMiddleware,
  combineSlices,
} from '@reduxjs/toolkit'

import { type TypedUseSelectorHook, useSelector } from 'react-redux'
import { generalErrorModals } from '@dis/modals/src/generalErrorModals'
import { apiSlice } from './api/apiSlice'
import { centralModalLoaderSlice } from './centralModalLoader/centralModalLoaderSlice'
import { centralModalDialogSlice } from './centralModalDialog/centralModalDialogSlice'
import { journeySlice } from './journeys/journeysSlice'
import { personasSlice } from './personas/personasSlice'
import { securitySlice } from './security/securitySlice'
import { tenantsSlice } from './tenants/tenantsSlice'
import { userSlice } from './user/userSlice'
import { userManagementSlice } from './userManagement/userManagementSlice'
import { generalSlice } from './general/slice'
import { searchSlice } from './search/searchSlice'
import { setupSecurityListeners } from './security/securityListeners'
import { mapDispatchToActions } from './utils'
import { backlogSlice } from './backlog/backlogSlice'
import { tableSlice } from './table/tableSlice'
import { centralNotificationSlice } from './centralNotification/centralNotificationSlice'
import { atlasesSlice } from './atlases/atlasesSlice'
import { archivedTenantsSlice } from './archivedTenants/archivedTenantSlice'
import { commentsSlice } from './comments/commentsSlice'

export const actions = {
  [apiSlice.reducerPath]: apiSlice.actions,
  [archivedTenantsSlice.reducerPath]: archivedTenantsSlice.actions,
  [atlasesSlice.reducerPath]: atlasesSlice.actions,
  [backlogSlice.reducerPath]: backlogSlice.actions,
  [centralModalDialogSlice.reducerPath]: centralModalDialogSlice.actions,
  [centralModalLoaderSlice.reducerPath]: centralModalLoaderSlice.actions,
  [centralNotificationSlice.reducerPath]: centralNotificationSlice.actions,
  [commentsSlice.reducerPath]: commentsSlice.actions,
  [generalSlice.reducerPath]: generalSlice.actions,
  [journeySlice.reducerPath]: journeySlice.actions,
  [personasSlice.reducerPath]: personasSlice.actions,
  [searchSlice.reducerPath]: searchSlice.actions,
  [securitySlice.reducerPath]: securitySlice.actions,
  [tableSlice.reducerPath]: tableSlice.actions,
  [tenantsSlice.reducerPath]: tenantsSlice.actions,
  [userSlice.reducerPath]: userSlice.actions,
  [userManagementSlice.reducerPath]: userManagementSlice.actions,
}

const rootReducer = combineSlices(
  apiSlice,
  archivedTenantsSlice,
  atlasesSlice,
  backlogSlice,
  centralModalDialogSlice,
  centralModalLoaderSlice,
  centralNotificationSlice,
  commentsSlice,
  generalSlice,
  journeySlice,
  personasSlice,
  searchSlice,
  securitySlice,
  tableSlice,
  tenantsSlice,
  userSlice,
  userManagementSlice,
)

export const listeners = [setupSecurityListeners]

const listenerMiddlewareInstance = createListenerMiddleware({
  onError: (error) => {
    console.error('Saga error:', error)

    dispatchedActions.centralModalLoader.forceHideModalLoader()
    dispatchedActions.centralModalDialog.hideAllModalDialogs()
    generalErrorModals.default(error as any)
  },
})

export const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    // serializableCheck false is necessary due to callbacks in the central modal config
    return getDefaultMiddleware({ serializableCheck: false }).concat(
      listenerMiddlewareInstance.middleware,
    )
  },
  reducer: rootReducer,
})

export type Store = typeof store
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>

export type AppStartListening = TypedStartListening<RootState, AppDispatch>
export type AppListenerEffectAPI = ListenerEffectAPI<RootState, AppDispatch>

export const startAppListening = listenerMiddlewareInstance.startListening as AppStartListening

export const dispatchedActions = mapDispatchToActions<typeof actions>(actions, store)

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
