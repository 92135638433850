import { useEffect, useMemo, useState } from 'react'
import { getImage } from '@dis/api/src/file'
import { AxiosInstances } from '@dis/api'
import { hash } from 'object-code'
import { Buffer } from 'buffer'

export const useImageData = (url: string, priority = 0) => {
  const [iterator, setIterator] = useState(1)

  useEffect(() => {
    if (url) {
      const timeoutRef = setTimeout(() => {
        getImage({ url }).then(
          () => {
            setIterator(Date.now())
          },
          (error) => {
            console.error('useImageData error: ', error)
          },
        )
      }, priority * 50)

      return () => {
        clearTimeout(timeoutRef)
      }
    }
  }, [priority, url])

  const data = useMemo(() => {
    if (url && iterator) {
      const id = hash({ url }).toString()

      const cachedData = AxiosInstances.axiosGetImageInstance.storage?.data?.[id]?.data
        ?.data as ArrayBuffer

      if (cachedData) {
        const isSvg = Buffer.from(cachedData).toString().toLowerCase().includes('<svg')

        if (isSvg) {
          let cleanedData = Buffer.from(cachedData)
            .toString('utf8')
            .replace(/^<\?(xml)([^>]*)>/i, '')
            .replace(/<!([^>]*)>/i, '')
            .replace(/<metadata>.*<\/metadata>/i, '')

          const svgMatch = cleanedData.match(/<svg([^>]*>)/i)
          let svg = svgMatch?.[0] || ''

          const viewBox = svg.match(/viewBox="([^"]*)"/i)
          const viewBoxValues = viewBox?.[1]?.split(' ')

          const width = svg.match(/width="([^"]*)"/i)
          const height = svg.match(/height="([^"]*)"/i)

          /**
           * Sometimes the width and/or height is in percentage unit so the rendered image is
           * too small. The solution is to fill width and height props of the SVG with static values.
           */
          if (
            (width?.[1].includes('%') || height?.[1]?.includes('%')) &&
            viewBoxValues?.[2] &&
            viewBoxValues?.[3]
          ) {
            const newWidth = parseInt(viewBoxValues?.[2], 10)
            const newHeight = parseInt(viewBoxValues?.[3], 10)

            svg = svg.replace(/width="([^"]*)"/i, `width="${newWidth}"`)
            svg = svg.replace(/height="([^"]*)"/i, `height="${newHeight}"`)

            cleanedData = cleanedData.replace(/<svg([^>]*>)/i, svg)
          }

          return `data:image/svg+xml;utf-8,${encodeURIComponent(cleanedData)}`
        }

        return `data:image/png;base64, ${Buffer.from(cachedData).toString('base64')}`
      }
    }

    return ''
  }, [iterator, url])

  return data
}
