import React, { MouseEventHandler, ReactNode } from 'react'
import { Grid } from '@mui/material'
import DeleteIconSmall from '@dis/assets/src/icons/DeleteIconSmall.svg'
import PencilIcon from '@dis/assets/src/icons/PencilIcon.svg'
import { cx } from '@emotion/css'
import AddIconSmall from '@dis/assets/src/icons/AddIconSmall.svg'
import { styles as journeyStyles } from '@dis/styles/src/journey'
import { colors } from '@dis/styles'
import { CommentRoundIcon } from '@dis/modules/src/journey/common/CommentRoundIcon'
import { styles, buttonTransition, buttonStyle } from './styles'
import { RoundIconButton } from '../RoundIconButton/RoundIconButton'
import { SvgImage } from '../SvgImage/SvgImage'

type Props = {
  anchorEl?: HTMLElement
  canEdit?: boolean
  children?: ReactNode
  isContentCentered?: boolean
  isEditing?: boolean
  isExtraPadding?: boolean
  isOwner?: boolean
  isShiftButton?: boolean
  onAddClick?: VoidFunction
  onCommentClick?: MouseEventHandler<HTMLButtonElement>
  onDeleteClick?: VoidFunction
  onEditClick?: VoidFunction
  thread?: string
  tooltip?: string
  width: number
}

export const JourneyCell = ({
  canEdit = false,
  children,
  isContentCentered = false,
  isEditing = false,
  isExtraPadding = false,
  onAddClick,
  onDeleteClick,
  onEditClick,
  onCommentClick,
  anchorEl,
  tooltip,
  width,
  thread,
  isOwner,
  isShiftButton,
}: Props) => {
  return (
    <Grid
      width={width}
      className={cx(styles.body, {
        [styles.extraPadding]: isExtraPadding,
        [styles.centered]: isContentCentered,
      })}>
      {onEditClick && canEdit && !isEditing && (
        <RoundIconButton
          className={`${buttonStyle({
            left: 1,
            top: 1,
          })} ${buttonTransition}`}
          outline="grey"
          icon={<SvgImage src={PencilIcon} />}
          size="small"
          onClick={onEditClick}
        />
      )}

      {children}

      {onAddClick && canEdit && !isEditing && (
        <Grid container flexDirection="column" alignItems="center">
          <RoundIconButton
            className={`${buttonStyle({ position: 'relative' })} ${buttonTransition}`}
            size="small"
            icon={
              <SvgImage
                color={colors.kpmg.pacificBlue}
                src={AddIconSmall}
                className={journeyStyles.blueIconStyles}
              />
            }
            outline="grey"
            onClick={onAddClick}
            title={tooltip}
          />
        </Grid>
      )}

      {onDeleteClick && canEdit && !isEditing && (
        <RoundIconButton
          icon={<SvgImage src={DeleteIconSmall} />}
          outline="grey"
          className={`${buttonStyle({ right: 1, top: 1, zIndex: 1 })} ${buttonTransition}`}
          size="small"
          onClick={onDeleteClick}
        />
      )}
      {onCommentClick && canEdit && !isEditing && (
        <CommentRoundIcon
          anchorEl={anchorEl}
          thread={thread}
          handleOpen={onCommentClick}
          isOwner={isOwner}
          isShiftButton={isShiftButton}
        />
      )}
    </Grid>
  )
}
