import { useCallback } from 'react'
import { type RadioGroupProps, type TypographyProps } from '@mui/material'
import { useController, type Control } from 'react-hook-form'
import {
  Radio as RadioComponent,
  RadioProps as RadioComponentProps,
} from '@dis/components/src/Radio/Radio'
import { TypographyKeys } from '@dis/styles/src/theme'

export type Props = Omit<RadioComponentProps, 'value' | 'name' | 'color'> & {
  color?: RadioComponentProps['color']
  fontSize?: TypographyKeys
  formControl: Control<any>
  inlineLabelSx?: TypographyProps['sx']
  label?: string
  name: string
  options: Array<{ label: string; value: string | number | boolean }>
  shouldUnregister?: boolean
}

export const Radio = ({ formControl, shouldUnregister = false, name, ...restProps }: Props) => {
  const restPropsOnChange = restProps.onChange

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control: formControl,
    name,
    shouldUnregister,
  })

  const onChangeWrapper = useCallback<NonNullable<RadioGroupProps['onChange']>>(
    (e, value) => {
      onChange(e)
      restPropsOnChange?.(e, value)
    },
    [onChange, restPropsOnChange],
  )

  return <RadioComponent {...restProps} error={error} value={value} onChange={onChangeWrapper} />
}
