import { matchPath, useLocation, type Location } from 'react-router'
import { routeFullPaths } from '@dis/routing'

const isMatch = (paths: Array<string> | undefined, location: Location) => {
  if (!paths) {
    return false
  }

  return !!paths.find((path) => matchPath(path, location.pathname))
}

/**
 * Takes actual route and compares it with the list of routes.
 */
export const useRouteMatch = () => {
  const location = useLocation()

  const isLocalTemplatePreview = isMatch(routeFullPaths?.localTemplate, location)
  const isGlobalTemplatePreview = isMatch(routeFullPaths?.globalTemplate, location)
  const isPersona = isMatch(routeFullPaths?.persona, location)
  const isJourney = isMatch(routeFullPaths?.journey, location)
  const isJourneyFolder = isMatch(routeFullPaths?.journeyFolder, location)
  const isRecycleBinJourney = isMatch(routeFullPaths?.recycleBinJourney, location)
  const isSearch = isMatch(routeFullPaths?.search, location)
  const isCapabilityManagement = isMatch(routeFullPaths?.capabilityManagement, location)
  const isGlobalCapabilityManagement = isMatch(routeFullPaths?.globalCapabilityManagement, location)
  const isBacklog = isMatch(routeFullPaths?.userStory, location)
  const isAtlas = isMatch(routeFullPaths?.atlas, location)
  const isFolder = isMatch(routeFullPaths?.folder, location)
  const isGlobalTemplateManagement = isMatch(routeFullPaths?.globalTemplateManagement, location)
  const isGlobalUserManagement = isMatch(routeFullPaths?.globalUserManagement, location)

  return {
    isAtlas,
    isBacklog,
    isCapabilityManagement,
    isFolder,
    isGlobalCapabilityManagement,
    isGlobalTemplateManagement,
    isGlobalTemplatePreview,
    isGlobalUserManagement,
    isJourney,
    isJourneyFolder,
    isLocalTemplatePreview,
    isPersona,
    isRecycleBinJourney,
    isSearch,
    isTemplatePreview: isLocalTemplatePreview || isGlobalTemplatePreview,
  }
}
