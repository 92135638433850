import { useEffect } from 'react'
import { Outlet } from 'react-router'
import { useTenant, useTypedParams } from '@dis/hooks'
import { dispatchedActions, useAppSelector } from '@dis/redux'
import { selectAccessTokenExpireTimestamp } from '@dis/redux/src/security/selectors'
import { processUserSession } from '@dis/utils/src/msalInstance'

export const Authenticated = () => {
  const { tenantId } = useTypedParams()

  const accessTokenExpireTimestamp = useAppSelector(selectAccessTokenExpireTimestamp)

  // Init tenant data and save to redux
  useTenant()

  useEffect(() => {
    dispatchedActions.tenants.setSelectedTenantId(tenantId)

    // Delete tenant data from redux
    if (!tenantId) {
      dispatchedActions.tenants.setTenantData(undefined)
    }

    // We assume the webpage is reload if the user is logged out but still it's more secure to delete the redux manually
    return () => {
      dispatchedActions.backlog.init()
      dispatchedActions.journeys.init()
      dispatchedActions.personas.init()
      dispatchedActions.search.init()
      dispatchedActions.tenants.init()
      dispatchedActions.userManagement.init()

      // TODO: clear BE API cache
    }
  }, [tenantId])

  // Refresh access token some minutes before expiration time
  useEffect(() => {
    if (accessTokenExpireTimestamp) {
      const timeout = accessTokenExpireTimestamp - Date.now() - 2 * 60 * 1000

      if (timeout > 0) {
        const timeoutRef = setTimeout(processUserSession, timeout)

        return () => {
          clearTimeout(timeoutRef)
        }
      }
    }
  }, [accessTokenExpireTimestamp])

  return <Outlet />
}
