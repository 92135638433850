import { languages } from '@dis/languages/src/translations/languages'

export type Languages = {
  [K in keyof typeof languages]: K
}

export type LanguagesUnion = keyof typeof languages

export const LANGUAGES = ((): Languages => {
  const ret: Record<string, string> = {}

  Object.keys(languages).forEach((lng) => {
    ret[lng] = lng
  })

  return ret as Languages
})()

const changeLanguageCodeList: ReadonlyArray<LanguagesUnion> = ['en', 'cs', 'de', 'fr', 'ar']

export const changeLanguageList = changeLanguageCodeList.map((code) => ({
  label: languages[code],
  value: code,
}))
