import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AcceptIcon from '@dis/assets/src/icons/AcceptIcon.svg'
import { tKeys } from '@dis/languages'
import BinIcon from '@dis/assets/src/icons/BinIcon.svg'
import { colors } from '@dis/styles/src/colors'
import EditIcon from '@dis/assets/src/icons/EditIcon.svg'
import { SvgImage } from '@dis/components'
import { cx } from '@emotion/css'
import { styles } from './styles'

type StatusButtonProps = {
  disabled?: boolean
  isDeleted?: boolean
  isDone?: boolean
  onClick?: VoidFunction
  withText?: boolean
}

export const StatusButton = ({ onClick, isDone, withText, isDeleted }: StatusButtonProps) => {
  const { t } = useTranslation()

  const published = isDone && !isDeleted && <SvgImage color={colors.gray.gray0} src={AcceptIcon} />
  const inProgress = !isDone && !isDeleted && <SvgImage color={colors.gray.gray0} src={EditIcon} />
  const deleted = isDeleted && <SvgImage color={colors.gray.gray0} src={BinIcon} />

  const backgroundColor =
    isDone && !isDeleted
      ? colors.semantic.green
      : !isDone && !isDeleted
        ? colors.kpmg.pacificBlue
        : isDeleted
          ? colors.semantic.red
          : ''

  let text = ''
  if (withText) {
    text =
      isDone && !isDeleted
        ? t(tKeys.common.publish)
        : !isDone && !isDeleted
          ? t(tKeys.common.inProgress)
          : isDeleted
            ? t(tKeys.statusButton.deleted)
            : ''
  }

  return (
    <Button
      className={cx(styles.statusButton.root, {
        [styles.statusButton.withoutText]: !withText,
      })}
      onClick={onClick}
      style={{
        backgroundColor,
        cursor: isDeleted ? 'not-allowed' : 'pointer',
        textTransform: 'uppercase',
      }}
      startIcon={published || inProgress || deleted}
      size="small"
      variant="contained">
      {text}
    </Button>
  )
}
