import { useMemo, ReactNode } from 'react'
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { ActiveDnD } from '@dis/types/src/DragAndDropTypes'
import { useTable } from '../../../../context/src/table/useTable'
import { SortableItem } from './SortableItem'

type Props<RowType> = {
  renderRow(row: RowType): ReactNode
}

export const SortableList = <RowType,>({ renderRow }: Props<RowType>) => {
  const { sortedRows, idExtractor, onDndChange } = useTable()

  const items = useMemo(
    () =>
      sortedRows.map(
        (rowItem): ActiveDnD<RowType> => ({ data: rowItem, id: idExtractor(rowItem) }),
      ),
    [idExtractor, sortedRows],
  )

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  return (
    <DndContext
      modifiers={[restrictToParentElement]}
      sensors={sensors}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id)
          const overIndex = items.findIndex(({ id }) => id === over.id)

          onDndChange?.({
            fromIndex: activeIndex,
            sortedRows: arrayMove(items, activeIndex, overIndex),
            toIndex: overIndex,
          })
        }
      }}>
      <SortableContext items={items}>
        <>
          {items.map((item) => (
            <SortableItem id={item.id} key={item.id}>
              {renderRow(item.data)}
            </SortableItem>
          ))}
        </>
      </SortableContext>
    </DndContext>
  )
}
