import { css } from '@emotion/css'
import { colors, theme, typography, typographyFontWeight } from '@dis/styles'

export const styles = {
  bubble: css`
    border-radius: 100%;
    padding: 0;
    margin: 0;
    width: 2rem;
    height: 2rem;
    min-width: 0;
    background-color: ${colors.kpmg.cobaltLight};
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    border-style: solid;
    border-width: 2px;
    border-color: ${colors.kpmg.cobaltLight};
    color: ${colors.kpmg.pacificBlue};
    font-weight: ${typographyFontWeight.bold};
    font-size: ${typography.paragraph.body.fontSize};
    line-height: ${typography.paragraph.body.lineHeight};
  `,
  leftColumn: css`
    display: flex;
    align-self: center;
    justify-content: center;
  `,
  meInfoContainer: css`
    position: relative;
    display: flex;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(4)} ${theme.spacing(3)};

    & > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :after {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.gray.gray10};
    }
  `,
  rightColumn: css`
    display: flex;
    flex-direction: column;

    & > * {
      width: 100%;
      text-align: start;
    }
  `,
}
