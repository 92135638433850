import { RootState } from '@dis/redux'

export const selectLanguage = (state: RootState) => {
  return state.user.language
}

export const selectUserName = (state: RootState) => {
  return state.user.name
}

export const selectIsLanguageRtl = (state: RootState) => {
  return state.user.language === 'ar'
}
