import { useCallback } from 'react'
import {
  Switch,
  type SwitchProps,
  FormControlLabel,
  type TypographyProps,
  FormGroup,
} from '@mui/material'
import { FieldError } from 'react-hook-form/dist/types'
import { getTypographyProp, TypographyKeys } from '@dis/styles'
import { ErrorNotice } from '../ErrorNotice/ErrorNotice'
import { styles } from './styles'

export type ToggleSwitchProps = Omit<SwitchProps, 'name' | 'color'> & {
  color?: SwitchProps['color']
  errorObject?: Error | FieldError
  fontSize?: TypographyKeys
  inlineLabelSx?: TypographyProps['sx']
  label?: string
}

export const ToggleSwitch = ({
  label,
  color = 'primary',
  errorObject,
  fontSize = 'paragraph.medium',
  inlineLabelSx = {},
  onChange: onChangeProp,
  sx,
  ...restProps
}: ToggleSwitchProps) => {
  const onChangeWrapper = useCallback<NonNullable<SwitchProps['onChange']>>(
    (e, checked) => {
      onChangeProp?.(e, checked)
    },
    [onChangeProp],
  )

  const additionalProps: Record<string, boolean> = {}

  if ('value' in restProps) {
    additionalProps.checked = !!restProps.value
  }

  return (
    <FormGroup sx={sx}>
      <FormControlLabel
        className={styles.controlLabel}
        control={
          <Switch
            className={styles.container}
            {...restProps}
            {...additionalProps}
            color={color}
            onChange={onChangeWrapper}
          />
        }
        componentsProps={{
          typography: {
            sx: { fontSize: getTypographyProp(fontSize, 'fontSize'), ...inlineLabelSx },
          },
        }}
        label={label}
      />
      <ErrorNotice error={errorObject} />
    </FormGroup>
  )
}
