import { useMemo } from 'react'
import { Breadcrumbs } from '@mui/material'
import { useMatches } from 'react-router'
import { RouteHandle, RouteNames } from '@dis/routing'
import { useAppSelector } from '@dis/redux'
import { selectUserRole } from '@dis/redux/src/security/selectors'
import { UserRole } from '@dis/redux/src/security/securityTypes'
import BreadcrumbSeparator from '@dis/assets/src/icons/BreadcrumbSeparator.svg'
import { colors } from '@dis/styles'
import { SvgImage } from '@dis/components'
import { styles } from './styles'
import { Link } from './Link'

export const Breadcrumb = () => {
  const userRole = useAppSelector(selectUserRole)

  const matches = useMatches()

  const crumbs = useMemo(
    () =>
      matches
        .filter((match) => {
          const handle = match.handle as RouteHandle | undefined
          const handleName = handle?.name as RouteNames | undefined

          if (
            handleName === 'tenants' &&
            !!userRole &&
            [UserRole.User, UserRole.PowerUser, UserRole.TenantAdmin].includes(userRole)
          ) {
            return false
          }

          return !!handle?.crumb
        })
        .map((match) => {
          const handle = match.handle as RouteHandle
          const handleName = handle?.name as RouteNames | undefined

          const ret = { ...(handle?.crumb?.() || {}), routeKey: handle?.name as RouteNames }

          if (
            handleName === 'tenant' &&
            userRole &&
            [UserRole.User, UserRole.PowerUser, UserRole.TenantAdmin].includes(userRole) &&
            handle?.crumb
          ) {
            ret.icon = ret.alternativeIcon
          }

          return ret
        }),
    [matches, userRole],
  )

  return (
    <Breadcrumbs
      separator={<SvgImage src={BreadcrumbSeparator} color={colors.gray.gray3} />}
      aria-label="breadcrumb"
      className={styles.root}>
      {crumbs.map(({ icon, routeKey, routeToKey }, index) => {
        return (
          <Link
            key={routeKey}
            routeKey={routeKey}
            routeToKey={routeToKey}
            icon={icon}
            isLast={index == crumbs.length - 1}
          />
        )
      })}
    </Breadcrumbs>
  )
}
