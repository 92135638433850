import React, { useCallback, ChangeEvent } from 'react'
import {
  FormGroup,
  FormControlLabel,
  FormLabel,
  type TypographyProps,
  Checkbox as CheckboxComponent,
  type CheckboxProps as CheckboxComponentProps,
  type FormGroupProps,
} from '@mui/material'
import { FieldError } from 'react-hook-form/dist/types'
import { CheckboxLabelPlacement, TypographyKeys, getTypographyProp } from '@dis/styles/src/theme'
import CheckboxCheckedIcon from '@dis/assets/src/icons/CheckboxCheckedIcon.svg'
import CheckboxUncheckedIcon from '@dis/assets/src/icons/CheckboxUncheckedIcon.svg'
import CheckboxIndeterminateIcon from '@dis/assets/src/icons/CheckboxIndeterminateIcon.svg'
import { colors } from '@dis/styles/src/colors'
import { DISALLOW_DND } from '@dis/constants'
import { ErrorNotice } from '../ErrorNotice/ErrorNotice'
import { SvgImage } from '../SvgImage/SvgImage'

type Values = string | boolean | number

export type CheckboxProps = Omit<CheckboxComponentProps, 'onChange'> & {
  checkboxClassName?: string
  className?: string
  containerSx?: FormGroupProps['sx']
  errorObject?: Error | FieldError
  fontSize?: TypographyKeys
  inlineLabel?: string
  inlineLabelSx?: TypographyProps['sx']
  labelPlacement?: CheckboxLabelPlacement
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: Values) => void
  title?: string
  upperLabel?: string
  values?: {
    checked: Values
    unchecked: Values
  }
}

export const Checkbox = ({
  containerSx,
  errorObject,
  fontSize = 'paragraph.medium',
  upperLabel,
  inlineLabel,
  className,
  checkboxClassName,
  values = {
    checked: true,
    unchecked: false,
  },
  inlineLabelSx = {},
  color = 'primary',
  onChange: onChangeProp,
  labelPlacement = 'end',
  ...restProps
}: CheckboxProps) => {
  const onChangeWrapper = useCallback<NonNullable<CheckboxComponentProps['onChange']>>(
    (e, checked) => {
      onChangeProp?.(e, checked ? values.checked : values.unchecked)
    },
    [onChangeProp, values],
  )

  const additionalProps: Record<string, boolean> = {}

  if ('value' in restProps) {
    additionalProps.checked = restProps.value === values.checked
  }

  return (
    <FormGroup sx={containerSx}>
      <>
        {!!upperLabel && <FormLabel>{upperLabel}</FormLabel>}
        <FormControlLabel
          control={
            <CheckboxComponent
              {...restProps}
              {...additionalProps}
              className={checkboxClassName}
              color={color}
              onChange={onChangeWrapper}
              icon={<SvgImage src={CheckboxUncheckedIcon} color={colors.gray.gray40} />}
              checkedIcon={<SvgImage src={CheckboxCheckedIcon} />}
              indeterminateIcon={<SvgImage src={CheckboxIndeterminateIcon} />}
            />
          }
          className={className}
          componentsProps={{
            typography: {
              className: DISALLOW_DND,
              sx: { fontSize: getTypographyProp(fontSize, 'fontSize'), ...inlineLabelSx },
            },
          }}
          label={inlineLabel || ''}
          labelPlacement={labelPlacement}
        />
        <ErrorNotice error={errorObject} />
      </>
    </FormGroup>
  )
}
