export * from './date'
export { ExtendedError } from './ExtendedError'

export const removeHTMLTags = (text: string) => {
  return text.replace(/<\/?[^>]+(>|$)/g, ' ')
}

export const shortenText = (text: string, maxLength: number) => {
  return text.length > maxLength ? text.substring(0, maxLength).concat('..') : text
}

export const typedObjectKeys = <T extends Record<string, any>>(obj: T): Array<keyof T> => {
  return Object.keys(obj) as unknown as Array<keyof T>
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

export const typedObjectEntries = <T extends Record<string, any>>(obj: T): Entries<T> => {
  return Object.entries(obj) as Entries<T>
}

/**
 * Similar to Array.map function but this is for objects.
 * Returns object with the same keys as the original object.
 */
export const typedObjectMap = <T extends Record<string, any>, U>(
  obj: T,
  fn: (key: keyof T, value: T[keyof T]) => U,
): Record<keyof T, U> => {
  const newObject: Partial<Record<keyof T, U>> = {}

  typedObjectEntries(obj).forEach(([key, value]) => {
    newObject[key] = fn(key, value)
  })

  return newObject as Record<keyof T, U>
}

export const isFirstLetterRtl = (str: string) => {
  if (str.trim().length === 0) {
    return false
  }
  const code = str.trim().codePointAt(0)

  if (code === undefined) {
    return false
  }

  return (
    (code >= 0x0590 && code <= 0x05ff) || // Hebrew
    (code >= 0x0600 && code <= 0x06ff) || // Arabic
    (code >= 0x0750 && code <= 0x077f) || // Arabic Supplement
    (code >= 0x07c0 && code <= 0x07ff) || // N'Ko
    (code >= 0x0800 && code <= 0x083f) || // Samaritan
    (code >= 0x08a0 && code <= 0x08ff) || // Arabic Extended-A
    (code >= 0xfb1d && code <= 0xfb4f) || // Hebrew Presentation Forms
    (code >= 0xfe70 && code <= 0xfeff) || // Arabic Presentation Forms-B
    (code >= 0x10800 && code <= 0x1083f) || // Cypriot Syllabary
    (code >= 0x10a00 && code <= 0x10a5f) || // Kharoshthi
    (code >= 0x10b00 && code <= 0x10b3f) || // Avestan
    (code >= 0x10b60 && code <= 0x10b7f) || // Inscriptional Pahlavi
    (code >= 0x10c00 && code <= 0x10c4f) || // Old Turkic
    (code >= 0x1ee00 && code <= 0x1eeff) // Arabic Mathematical Alphabetic Symbols
  )
}
