import { createRef, FC, useCallback, useState } from 'react'
import { Button, Grid, IconButton } from '@mui/material'
import ImageIcon from '@mui/icons-material/Image'
import Cropper, { type ReactCropperElement } from 'react-cropper'
import { useTranslation } from 'react-i18next'
import 'cropperjs/dist/cropper.css'
import { cx } from '@emotion/css'
import { colors } from '@dis/styles'
import { tKeys } from '@dis/languages'
import { DISALLOW_DND } from '@dis/constants'
import { SvgImage, Modal } from '@dis/components'
import PlusIcon from '@dis/assets/src/icons/PlusIcon.svg'
import { ModalDialogProps } from '@dis/types/src/ModalDialogTypes'
import { styles } from './styles'
import { FileInput } from './FileInput'

const msg = tKeys.modules.uploadImage

type Props = {
  canEdit: boolean
  isAddButton?: boolean
  isModalDialog?: boolean
  isPreviewRound?: boolean
  onUploadImage: (image: File) => void
  /** recommend size is between 60 and 200 */
  previewImageDimension?: number
}

export const UploadImage: FC<Props> = ({
  canEdit,
  isAddButton = false,
  isModalDialog = false,
  isPreviewRound = false,
  onUploadImage,
  previewImageDimension,
}) => {
  const { t } = useTranslation()
  const [image, setImage] = useState<string>()
  const [fileName, setFileName] = useState<string>('')

  const cropperRef = createRef<ReactCropperElement>()

  const handleCloseModal = useCallback(() => {
    setImage(undefined)
  }, [])

  const handleNewImage = useCallback<NonNullable<ModalDialogProps['onInputClick']>>(
    (e) => {
      e.preventDefault()
      if (!e.target?.files?.[0]) return

      const file = e.target.files[0]

      if (isModalDialog) {
        const reader = new FileReader()
        reader.onload = () => {
          setImage(reader.result as unknown as string)
          setFileName(file.name)
        }
        reader.readAsDataURL(file)
      } else {
        onUploadImage(file)
      }
    },
    [isModalDialog, onUploadImage],
  )

  const getCropData = useCallback(() => {
    const callback = (blob: Blob | null) => {
      if (blob) {
        // remove file extension and add .jpeg
        const name = `${fileName.split('.')[0]}-croppedImage.png`
        const file = new File([blob], name, { type: 'image/png' })

        onUploadImage(file)

        handleCloseModal()
      }
    }

    if (typeof cropperRef.current?.cropper !== 'undefined') {
      cropperRef.current?.cropper.getCroppedCanvas().toBlob(callback, 'image/png')
    }
  }, [cropperRef, fileName, handleCloseModal, onUploadImage])

  if (!canEdit) return null

  if (!image) {
    return isAddButton ? (
      <Button
        className={styles.addPhoto}
        component="label"
        variant="outlined"
        startIcon={<SvgImage src={PlusIcon} />}>
        {t(msg.addPhoto)}
        <FileInput onChange={handleNewImage} />
      </Button>
    ) : (
      <IconButton component="label">
        <ImageIcon style={{ color: colors.gray.gray40 }} />
        <FileInput onChange={handleNewImage} />
      </IconButton>
    )
  }

  if (isModalDialog) {
    return (
      <Modal
        onCrossBtnClick={handleCloseModal}
        primaryBtnText={t(msg.upload)}
        secondaryBtnText={t(msg.changeImage)}
        onInputClick={handleNewImage}
        onPrimaryBtnClick={getCropData}
        isSecondaryBtnInput
        isOpen
        textInFooter={t(msg.info)}
        width="auto"
        title={t(msg.title)}>
        <div className={DISALLOW_DND}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Cropper
                aspectRatio={1}
                autoCropArea={1}
                background={false}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                className={styles.cropper}
                guides
                initialAspectRatio={1}
                minCropBoxHeight={60}
                minCropBoxWidth={60}
                preview=".img-preview"
                ref={cropperRef}
                responsive
                src={image}
                viewMode={1}
              />
            </Grid>
            <Grid item xs={3}>
              <div
                className={cx('img-preview', styles.preview(previewImageDimension, isPreviewRound))}
              />
            </Grid>
          </Grid>
        </div>
      </Modal>
    )
  }

  return null
}
