import { HTMLAttributeAnchorTarget, ReactNode, useCallback } from 'react'
import { Button, type ButtonProps } from '@mui/material'
import { Header, LoadingAnimation, IconButton } from '@dis/components'
import { useTranslation } from 'react-i18next'
import { styles } from './styles'

export type DashboardContainerProps = {
  canCreate?: boolean
  children?: ReactNode
  className?: string
  gridArea?: string
  href?: string
  isLoader?: boolean
  navigate?: VoidFunction
  ownCreate?: ReactNode
  sendCreate?: VoidFunction
  target?: HTMLAttributeAnchorTarget
  textButtonTitle: string
  title: string
}

export const DashboardContainer = ({
  gridArea,
  children,
  canCreate,
  title,
  sendCreate,
  className,
  navigate,
  textButtonTitle,
  ownCreate,
  isLoader = false,
  href,
  target = '_self',
}: DashboardContainerProps) => {
  const { t } = useTranslation()

  const sendCreateWrapper = useCallback<NonNullable<ButtonProps['onClick']>>(() => {
    sendCreate?.()
  }, [sendCreate])

  return (
    <div className={className ?? styles.landingInnerContainerLayout} style={{ gridArea }}>
      <div className={styles.buttonsContainer}>
        <Header variant="h3" className={styles.title}>
          {t(title)}
        </Header>
        <div className={styles.buttonWrapper}>
          <Button
            variant="outlined"
            color="primary"
            onClick={navigate}
            component="a"
            href={href}
            target={target}>
            {t(textButtonTitle)}
          </Button>

          {canCreate && !!sendCreate && (
            <div>
              <IconButton
                color="primary"
                iconVariant="add"
                onClick={sendCreateWrapper}
                variant="outlined"
              />
            </div>
          )}
          {canCreate && !!ownCreate && ownCreate}
        </div>
      </div>
      {isLoader ? (
        <div className={styles.loadingAnimationContainer}>
          <LoadingAnimation outerStyle={{ height: '14vw', width: '14vw' }} />
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}
