import { ReactNode, useCallback, useState } from 'react'
import { type TextFieldProps } from '@mui/material'
import { type Control, useController } from 'react-hook-form'
import { Input as InputComponent, InputProps as InputComponentProps } from '@dis/components'
import { submitByVirtualButton } from '../../utils'

export type Props = Omit<
  InputComponentProps,
  'value' | 'name' | 'error' | 'isFocused' | 'setIsFocused'
> & {
  children?: ReactNode
  formControl: Control<any>
  name: string
  sameStyleForDisabled?: boolean
  shouldUnregister?: boolean
  submitOnBlur?: boolean
  submitOnEnter?: boolean
  textAlign?: 'start' | 'center' | 'end'
  underline?: boolean
  width?: string
}

export const Input = ({
  name,
  startIcon,
  formControl,
  shouldUnregister = false,
  submitOnEnter = false,
  submitOnBlur = false,
  onBlur: onBlurProp,
  children,
  ...restProps
}: Props) => {
  const [isFocused, setIsFocused] = useState(false)

  const {
    field: { onBlur, onChange, value },
    fieldState: { error },
  } = useController({
    control: formControl,
    name,
    shouldUnregister,
  })

  const onBlurWrapper = useCallback<NonNullable<TextFieldProps['onBlur']>>(
    (e) => {
      onBlur()
      onBlurProp?.(e)
      setIsFocused(false)
      if (submitOnBlur || submitOnEnter) {
        const target = e.target as HTMLElement
        submitByVirtualButton(target)
      }
    },
    [onBlur, onBlurProp, submitOnBlur, submitOnEnter],
  )

  return (
    <InputComponent
      {...restProps}
      isFocused={isFocused}
      error={error}
      setIsFocused={setIsFocused}
      value={value}
      startIcon={startIcon}
      onChange={onChange}
      onBlur={onBlurWrapper}>
      {children}
    </InputComponent>
  )
}
