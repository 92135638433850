import React from 'react'
import { Box, type BoxProps, Button } from '@mui/material'
import { SearchBar, SwitchButton, TextIconButton, TextIconButtonProps } from '@dis/components'
import {
  AllPossibleSorters,
  CommonFilterType,
  SortType,
} from '@dis/types/src/PopoverFilterSortTypes'
import { cx } from '@emotion/css'
import ListIcon from '@dis/assets/src/icons/ListIcon.svg'
import Dashboard from '@dis/assets/src/icons/Dashboard.svg'
import { ViewEnum } from '@dis/types/src/GeneralTypes'
import { PopoverFilter, PopoverFilterProps } from '../PopoverSortFilter/PopoverFilter'
import { styles } from './styles'
import { PopoverSort } from '../PopoverSortFilter/PopoverSort'

type Props<F extends CommonFilterType, S extends AllPossibleSorters> = Pick<
  BoxProps,
  'mb' | 'mt' | 'sx' | 'className'
> & {
  actionButtonIconVariant?: TextIconButtonProps['iconVariant']
  actionButtonText?: string
  archivedButtonText?: string
  filter?: PopoverFilterProps<F>
  hasActionButton?: boolean
  hasArchivedButton?: boolean
  hasFilter?: boolean
  hasSearch?: boolean
  hasSort?: boolean
  hasViewSwitch?: boolean
  onActionButtonClick?: VoidFunction
  onArchivedButtonClick?: VoidFunction
  searchPlaceholder?: string
  searchValue?: string
  setSearchValue?: (searchValue: string) => void
  setSortBy?: React.Dispatch<React.SetStateAction<S | undefined>>
  setViewSwitch?: (value: ViewEnum) => void
  sortBy?: S
  sortType?: SortType
  viewSwitchValue?: ViewEnum
}

export const ScreenActionsPanel = <F extends CommonFilterType, S extends AllPossibleSorters>({
  actionButtonText,
  archivedButtonText,
  actionButtonIconVariant,
  onArchivedButtonClick,
  className,
  filter,
  hasActionButton = true,
  hasArchivedButton = false,
  hasSearch = true,
  hasFilter = true,
  hasSort = true,
  hasViewSwitch,
  mb = 6,
  mt,
  onActionButtonClick = () => undefined,
  searchPlaceholder,
  searchValue = '',
  setSearchValue = () => undefined,
  setViewSwitch = () => undefined,
  setSortBy,
  sortBy,
  sortType,
  sx,
  viewSwitchValue = ViewEnum.Table,
}: Props<F, S>) => {
  const { title = '', filters, type, setFilters } = filter || {}

  return (
    <Box className={cx(styles.actionsPanel, className)} mb={mb} mt={mt} sx={sx}>
      {hasSearch && (
        <SearchBar
          inputValue={searchValue}
          onChangeValue={setSearchValue}
          placeholder={searchPlaceholder}
          sx={{ marginInlineEnd: 'auto', maxWidth: '100%' }}
          size="medium"
          width="18.75rem"
        />
      )}

      {hasFilter && filters && setFilters && type && title && (
        <PopoverFilter
          buttonClassname={styles.popoverFilterButton}
          type={type}
          title={title}
          filters={filters}
          setFilters={setFilters}
        />
      )}

      {hasSort && setSortBy && (
        <PopoverSort<S> sortBy={sortBy} setSortBy={setSortBy} sortType={sortType} />
      )}

      {hasViewSwitch && setViewSwitch && (
        <SwitchButton<ViewEnum>
          onChange={setViewSwitch}
          leftIcon={ListIcon}
          leftIconSize="header.h2"
          leftValue={ViewEnum.Table}
          rightIcon={Dashboard}
          rightIconSize="header.h2"
          rightValue={ViewEnum.Card}
          value={viewSwitchValue}
          forceWidth="2.5rem"
        />
      )}
      {hasArchivedButton && (
        <Button variant="outlined" color="primary" onClick={onArchivedButtonClick}>
          {archivedButtonText}
        </Button>
      )}

      {hasActionButton && (
        <TextIconButton
          fontSize="paragraph.body"
          iconVariant={actionButtonIconVariant}
          onClick={onActionButtonClick}
          text={actionButtonText}
        />
      )}
    </Box>
  )
}
