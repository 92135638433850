import { NavigationBar } from '@dis/components/src/NavigationBar/NavigationBar'
import { tKeys } from '@dis/languages'
import { useTranslation } from 'react-i18next'
import { navBarLink } from '@dis/constants'
import { useAppSelector } from '@dis/redux'
import { selectUserRole } from '@dis/redux/src/security/selectors'
import { UserRole } from '@dis/types/src/UsersAndRoles'

const msg = tKeys.tutorials

export const TutorialNavigationBar = () => {
  const { t } = useTranslation()
  const actualUserRole = useAppSelector(selectUserRole)

  const isKpmgUser = actualUserRole === UserRole.KpmgAdmin || actualUserRole === UserRole.KpmgUser

  return isKpmgUser ? (
    <NavigationBar
      description={t(msg.tutorialMoreHelpDescription)}
      linkText={t(msg.tutorialMoreHelpLink)}
      title={t(msg.tutorialMoreHelpTitle)}
      link={navBarLink}
    />
  ) : null
}
