import React, { useCallback, useRef, ReactNode } from 'react'
import { type SelectProps } from '@mui/material'
import { useController, type Control } from 'react-hook-form'
import { Select as SelectComponent, SelectProps as SelectComponentProps } from '@dis/components'
import { submitByVirtualButton } from '../../utils'

export type Props = Omit<SelectComponentProps, 'value' | 'name' | 'error' | 'color'> & {
  children?: ReactNode
  dropdownClasses?: string
  formControl: Control<any>
  name: string
  shouldUnregister?: boolean
  submitOnChange?: boolean
}

export const Select = ({
  name,
  formControl,
  shouldUnregister = false,
  submitOnChange = false,
  onChange: onChangeProps,
  children,
  ...restProps
}: Props) => {
  const selectComponentRef = useRef()

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control: formControl,
    name,
    shouldUnregister,
  })

  const onChangeWrapper = useCallback<NonNullable<SelectProps['onChange']>>(
    (e, child) => {
      onChange(e)
      onChangeProps?.(e, child)

      if (submitOnChange && selectComponentRef.current) {
        submitByVirtualButton(selectComponentRef.current)
      }
    },
    [onChange, onChangeProps, submitOnChange],
  )

  return (
    <SelectComponent
      {...restProps}
      inputRef={selectComponentRef}
      errorObject={error}
      value={value}
      onChange={onChangeWrapper}>
      {children}
    </SelectComponent>
  )
}
