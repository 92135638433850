export const getClearName = (userName: string) => {
  const clearName = userName.replace(/[,.]/g, ' ').replace(/\s+/g, ' ')

  const nameParts = clearName.split(' ')

  const enhancedNameParts: string[] = []

  nameParts.forEach((part) => {
    enhancedNameParts.push(part.slice(0, 1).toUpperCase() + part.slice(1))
  })

  return enhancedNameParts.join(' ')
}
export const getSignature = (userName: string) => {
  const name = getClearName(userName)

  const nameParts = name.split(' ')

  const signatureArray: string[] = []

  if (nameParts.length && nameParts[0]) {
    signatureArray.push(nameParts[0].charAt(0))
  }
  if (nameParts.length > 1 && nameParts[nameParts.length - 1]) {
    signatureArray.push(nameParts[nameParts.length - 1].charAt(0))
  }

  return signatureArray.join('').toUpperCase()
}
