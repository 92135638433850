import graphql from 'babel-plugin-relay/macro'

export const GetUserActivityPresenceList = graphql`
  query QueriesGetUserActivityPresenceListQuery($input: UserActivityPresenceListQueryInput!) {
    userActivityPresenceList(input: $input) {
      items {
        metadata {
          currentSessionStart
          lastHeartbeat
          status
        }
        userInfo {
          email
          id
          name
        }
      }
    }
  }
`

export const GetJourneyCommentList = graphql`
  query QueriesGetJourneyCommentListQuery($input: JourneyCommentListQueryInput!) {
    journeyCommentList(input: $input) {
      threadOwnershipDisabled
      items {
        id
        content
        created
        updated
        metadata {
          userInfo {
            email
            name
            oid
          }
        }
        resourceId
        resourceType
        status
        threadCommentId
      }
    }
  }
`

export const GetJourneyThreadCommentList = graphql`
  query QueriesGetJourneyThreadCommentListQuery($input: JourneyThreadCommentListQueryInput!) {
    journeyThreadCommentList(input: $input) {
      items {
        content
        created
        id
        resourceId
        resourceType
        status
        threadCommentId
        updated
        metadata {
          userInfo {
            email
            name
            oid
          }
        }
      }
    }
  }
`
