import { css } from '@emotion/css'
import { colors } from '@dis/styles'

export const styles = {
  textarea: (direction: string) => css`
    direction: ${direction};

    '::placeholder': {
      color: ${colors.gray.gray100};
    }
  `,
}
