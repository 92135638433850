import React, { useCallback } from 'react'
import { useNavTo } from '@dis/hooks'
import { cx } from '@dis/styles'
import { SearchTypes } from '@dis/types/src/SearchTypes'
import { Paragraph } from '@dis/components'
import { styles as searchStyles } from './styles'

type CardProps = {
  atlasId?: number
  folderId?: number
  icon: React.ReactNode
  journeyId?: number
  name: string
  personaId?: number
  title: string
  type: SearchTypes
}

const Card = ({ name, icon, title, atlasId, folderId, journeyId, personaId, type }: CardProps) => {
  const { navigate } = useNavTo()

  const navigateTo = useCallback(() => {
    switch (type) {
      case SearchTypes.Atlas: {
        navigate('atlas', { atlasId: atlasId })
        break
      }
      case SearchTypes.Folder: {
        navigate('folder', { atlasId: atlasId, folderId: folderId })
        break
      }
      case SearchTypes.Persona: {
        navigate('persona', { personaId: personaId })
        break
      }
      case SearchTypes.Journey: {
        navigate('journey', { atlasId, journeyId })
        break
      }
    }
  }, [atlasId, folderId, journeyId, navigate, personaId, type])

  return (
    <>
      <div className={searchStyles.roundContainerWrapper} onClick={navigateTo}>
        <div className={cx(searchStyles.roundContainer, searchStyles.greyBorder)}>{icon}</div>
      </div>
      <Paragraph variant="medium" className={searchStyles.nameContainer} title={name}>
        {name}
      </Paragraph>
      <Paragraph variant="small" className={searchStyles.titleContainer}>
        {title}
      </Paragraph>
    </>
  )
}

export default Card
